import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  HomeIcon,
  UserGroupIcon,
  LocationMarkerIcon,
  DesktopComputerIcon,
  AdjustmentsIcon,
  DocumentReportIcon,
  LogoutIcon,
  ArchiveIcon,
  CollectionIcon,
  ShoppingBagIcon,
  CashIcon,
  CogIcon,
  ViewGridAddIcon,
  ChipIcon,
  CreditCardIcon,
  TicketIcon,
  ChartSquareBarIcon,
} from "@heroicons/react/outline";

import logo from "assets/img/whitelogo.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "actions/AuthActions";
import { ROLES } from "constant";
import { AuthSelector } from "selectors/AuthSelector";
import { includesRoles } from "libs";

export interface SideBarItem {
  key: string;
  label: string;
  path?: string;
  icon: string;
  roles?: ROLES[];
  children?: SideBarItem[];
}

interface SideBarProps {
  items?: SideBarItem[];
  expandedSideBar?: boolean;
}

// TODO: fix type.
const dictionary: any = {
  home: <HomeIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  userGroup: <UserGroupIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  locationMark: <LocationMarkerIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  desktopComputar: <DesktopComputerIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  adjusments: <AdjustmentsIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  documentReport: <ChartSquareBarIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  warehouses: <ArchiveIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  inventory: <CollectionIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  services: <ShoppingBagIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  cash: <CashIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  cog: <CogIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  gridAdd: <ViewGridAddIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  cron: <ChipIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  manual: <DocumentReportIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  billing: <CreditCardIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  invoice: <TicketIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
};

export const SideBar: React.FC<SideBarProps> = ({ items, expandedSideBar }) => {
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelector);
  const location = useLocation();
  const history = useHistory();
  const [selected, setSelected] = React.useState<string>(location.pathname);
  const [childSelected, setChildSelected] = React.useState<string>(
    location.pathname
  );

  const onItemSelect = (item: SideBarItem) => {
    if (!item.children) {
      history.push(item.path);
    }

    setSelected(item.path);

    if (!item.children) {
      setChildSelected("");
    }
  };

  const onChildItemSelect = (item: SideBarItem) => {
    history.push(item.path);
    setChildSelected(item.path);
  };

  const onLogout = () => {
    dispatch(logoutAction());
  };

  return (
    <div className={`
    transform overflow-auto ease-in-out transition-all duration-300 z-10
    ${expandedSideBar ? 'w-1/6' : 'w-0 translate-x-0'} flex flex-col bg-gray-200 border-r border-gray-300
    `}>
      <div className="h-16 text-white flex flex-col justify-center items-center bg-gray-900">
        <img className="w-32 mr" src={logo} alt="logo" />
        <span className="mt-2 font-bold text-xs uppercase">
          {auth.place?.name || "root"}
        </span>
      </div>
      <div className="flex flex-col flex-1 bg-gray-800 overflow-y-auto">
        <ul className="flex-1 text-gray-500 mt-4 text-sm">
          {items.map((item) => {
            return (
              includesRoles({ userRoles: auth.roles, roles: item.roles }) && (
                <li
                  key={item.label}
                  className={`flex flex-col py-1 px-1 sm:py-4 sm:px-4 my-2 mx-2 cursor-pointer rounded hover:text-gray-200 ${
                    selected?.indexOf(item.path) >= 0 &&
                    "transition duration-300 ease-in-out bg-gray-900 text-gray-200"
                  }`}
                  onClick={() => onItemSelect(item)}
                >
                  <div className="flex flex-row">
                    {dictionary[item.icon]}
                    <span className="ml-2 my-auto hidden sm:block">
                      {item.label}
                    </span>
                  </div>
                  {item.children && selected?.indexOf(item.path) >= 0 && (
                    <ul className="text-gray-500">
                      {item.children.map((children) => {
                        return (
                          includesRoles({
                            userRoles: auth.roles,
                            roles: children.roles,
                          }) && (
                            <li
                              key={children.label}
                              className={`flex flex-row py-1 px-2 sm:py-2 sm:px-4 my-2 cursor-pointer rounded hover:text-gray-200 ${
                                childSelected?.indexOf(children?.path) >= 0 &&
                                "bg-gray-900 text-gray-200"
                              }`}
                              onClick={() => onChildItemSelect(children)}
                            >
                              {dictionary[children.icon]}
                              <span className="ml-2 my-auto hidden sm:block">
                                {children.label}
                              </span>
                            </li>
                          )
                        );
                      })}
                    </ul>
                  )}
                </li>
              )
            );
          })}
        </ul>
      </div>
      <div
        className="flex p-4 bottom-0 justify-center text-white text-center bg-gray-900 text-gray-500 hover:text-gray-200 cursor-pointer"
        onClick={onLogout}
      >
        <LogoutIcon className="w-6 h-6 mx-auto sm:-mx-0" />
        <span className="hidden sm:block ml-2">Cerrar Sesión</span>
      </div>
      <div className="text-xs text-left px-4 py-1 bg-gray-900 text-gray-700">
        Version: {process.env.appVersion}
      </div>
    </div>
  );
};

export default SideBar;
