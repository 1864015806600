import { gql } from "@apollo/client";
import { deepCopy } from "libs";
import { authClient } from "libs/AuthClient";
import { PaginationParams } from "types/PaginationType";
import {
  CreatePlace,
  UpdatePlace,
  PlaceResponseType,
  GetPlacesQuery,
  PlaceType,
} from "types/PlaceType";

const createPlaceService = (placeDetails: CreatePlace) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation createPlace($input: CreatePlaceInput!) {
          createPlace(input: $input)
        }
      `,
      variables: {
        input: {
          ...placeDetails,
        },
      },
    })
    .then(({ data }): Object => {
      const placesData = data.createPlace;
      return {
        data: placesData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const updatePlaceService = (placeDetails: UpdatePlace) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation updatePlace($input: UpdatePlaceInput!) {
          updatePlace(input: $input)
        }
      `,
      variables: {
        input: {
          ...placeDetails,
        },
      },
    })
    .then(({ data }): Object => {
      const placesData = data.updatePlace;
      return {
        data: placesData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const placesService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query getPlaces($input: BasePaginationInput!) {
          places(input: $input) {
            docs {
              ... on Place {
                uuid
                name
                code
                active
                address
                placeNumber
                location {
                  type
                  coordinates
                }
                placeGroup {
                  uuid
                  name
                }
                city {
                  uuid
                  name
                }
                settings {
                  comission
                  comissionPercent
                  overrideComission
                }
                services {
                  uuid
                  name
                }
              }
            }
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            limit
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const placesData = data.places as PlaceResponseType;

      return {
        ...placesData,
      };
    });
};

const placesQueryService = ({ query }: GetPlacesQuery) => {
  return authClient()
    .query({
      query: gql`
        query placesQuery($query: String!) {
          placesQuery(query: $query) {
            uuid
            name
            code
          }
        }
      `,
      variables: {
        query,
      },
    })
    .then(({ data }): Object => {
      const placesData = data.placesQuery as PlaceType[];

      return placesData;
    });
};

export {
  placesService,
  placesQueryService,
  createPlaceService,
  updatePlaceService,
};
