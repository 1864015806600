import BarGraph from "components/CronJob/Graphs/BarGraph";
import AreaGraph from "components/CronJob/Graphs/AreaGraph";
import PieGraph from "components/CronJob/Graphs/PieGraph";
import * as React from "react";
import { useApolloClient } from "@apollo/client";

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex w-full h-96">
        <AreaGraph title="Ingreso Anual"/>
      </div>
      <div className="flex w-full flex-1 mt-12">
        <div className="w-1/2">
          <BarGraph title="Ingreso Semanal" />
        </div>
        <div className="w-1/2">
          <PieGraph title="Órdenes diarias" />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
