import * as React from "react";
import { DateTime } from "luxon";

import { useModal } from "components/UI/Modal/ModalProvider";
import Table from "components/UI/Table";
import {
  getWarehouseItemsService,
  removeItemFromWarehouseService,
} from "services/WarehouseService";
import { WarehouseItemType, WarehouseResponseType } from "types/WarehouseType";
import { toast } from "react-toastify";
import WarehouseItemManagementModal from "./WarehouseItemManagementModal";
import { useParams } from "react-router-dom";
import MessageModal from "components/UI/Message/MessageModal";

interface WarehouseItemsListProps {}

export const WarehouseItemsList: React.FC<WarehouseItemsListProps> = () => {
  const params = useParams();
  const [limit, setLimit] = React.useState<number>(25);
  const messageModal = useModal(MessageModal);
  const warehouseItemManagementModal = useModal(WarehouseItemManagementModal);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewWarehouseItemHandler = async () => {
    const res = await warehouseItemManagementModal({ ...params });

    if (res?.data) {
      toast.success(res?.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onEditWarehouseItemHandler = async (
    warehouseItem: WarehouseItemType
  ) => {
    const res = await warehouseItemManagementModal({
      warehouseItem,
      ...params,
    });

    if (res?.data) {
      toast.success(res?.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onRemoveWarehouseItemHandler = async (
    warehouseItem: WarehouseItemType
  ) => {
    const res = await messageModal({
      message: `Remover producto ${warehouseItem.item.name} del almacen?`,
      confirm: true,
    });

    if (res?.data) {
      const res2: any = await removeItemFromWarehouseService({
        warehouseItemUUID: warehouseItem.uuid,
        warehouseUUID: params.warehouseUUID,
      });

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de productos del almacen"
        limit={limit}
        columns={[
          { title: "Nombre", field: "item.name" },
          { title: "Cantidad", field: "quantity" },
          { title: "Precio", field: "price" },
        ]}
        data={async (query) => {
          const res = (await getWarehouseItemsService({
            input: {
              uuid: params.warehouseUUID,
              page: query.page + 1,
              query: query.search,
            },
          })) as WarehouseResponseType;
          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          {
            icon: "edit",
            tooltip: "Editar producto en almacen",
            onClick: (_, rowData: WarehouseItemType) =>
              onEditWarehouseItemHandler(rowData),
          },
          {
            icon: "delete",
            tooltip: "Eliminar producto de almacen",
            onClick: (_, rowData: WarehouseItemType) =>
              onRemoveWarehouseItemHandler(rowData),
          },
          {
            icon: "add",
            tooltip: "Agregar Almacen",
            isFreeAction: true,
            onClick: onNewWarehouseItemHandler,
          },
        ]}
      />
    </div>
  );
};

export default WarehouseItemsList;
