import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import logo from 'assets/img/logo-pagocheck.png';
import { loginService } from 'services/AuthService';
import { checkLogin, loginAction, logoutAction } from 'actions/AuthActions';
import { AuthSelector } from 'selectors/AuthSelector';
import { AuthType } from 'types/AuthType';
import { toast } from 'react-toastify';


export interface LoginProps {

}

interface StateType {
  username: string,
  password: string,
  placeCode: string,
}

const Login: React.FC<LoginProps> = () => {
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelector);
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [state, setState] = React.useState<StateType>({
    username: '',
    password: '',
    placeCode: ''
  });

  React.useEffect(() => {
    if (auth.loggedIn) {
      setRequesting(false);
    }

  }, [auth, setRequesting]);


  const onInputChange = (e: React.SyntheticEvent) => {
    const form = e.currentTarget as HTMLInputElement;
    const { value, name } = form;
    setState({
      ...state,
      [name]: value
    });
  }

  const onSubmitHandler = (e: React.SyntheticEvent) => {
    setRequesting(true);

    loginService(state)
      .then((res: AuthType) => {
        const validData = checkLogin(res);
        dispatch(loginAction(validData));
      }).catch(({ message }) => {
        console.error(message);
        toast.error(message);
        setRequesting(false);
        dispatch(logoutAction());
      })

    e.preventDefault();
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-12 w-auto" src={logo} alt="Pagocheck" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Panel
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={onSubmitHandler}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">Nombre de usuario</label>
              <input
                required
                id="username"
                name="username"
                type="text"
                value={state.username}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" placeholder="Nombre de usuario"
                onChange={onInputChange}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Contraseña</label>
              <input
                required
                id="password"
                name="password"
                type="password"
                value={state.password}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" placeholder="Contraseña"
                onChange={onInputChange}
              />
            </div>
            <div>
              <label htmlFor="placeCode" className="sr-only">Lugar</label>
              <input
                required={state.username !== 'root'}
                id="placeCode"
                name="placeCode"
                type="text"
                value={state.placeCode}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" placeholder="Código de sucursal"
                onChange={onInputChange}
              />
            </div>
          </div>

          <div>
            <button
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${requesting ? 'bg-gray-400 hover:bg-gray-500' : 'bg-gray-600 hover:bg-gray-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500`}
              disabled={requesting}
            >
              {`${requesting ? 'Iniciando Sesión' : 'Iniciar Sesión'}`}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;