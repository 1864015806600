import * as React from "react";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

import { useModal } from "components/UI/Modal/ModalProvider";
import { usersService } from "services/UserService";
import { UsersResponseType, UserType } from "types/UserType";
import Table from "components/UI/Table";
import UserManagementModal from "components/User/UserManagementModal";

interface UserListProps {}

const UserList: React.FC<UserListProps> = () => {
  const [limit, setLimit] = React.useState<number>(25);
  const userManagementModal = useModal(UserManagementModal);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewUserHanlder = async () => {
    const res = await userManagementModal();

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onEditUserHandler = async (user: UserType) => {
    const res = await userManagementModal({ user });

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de usuarios"
        limit={limit}
        columns={[
          { title: "Usuario", field: "username" },
          {
            title: "Roles",
            render: (rowData: UserType) => (
              <div className="w-full space-x-2">
                {rowData.rolesPermissions?.roles.map((rol) => {
                  return (
                    <span
                      key={rol}
                      className="w-12 rounded bg-gray-400 text-white p-1 text-xs"
                    >
                      {rol}
                    </span>
                  );
                })}
              </div>
            ),
          },
          {
            title: "Permisos",
            render: (rowData: UserType) => (
              <div className="w-full space-x-2">
                {rowData.rolesPermissions?.permissions.map((permission) => {
                  return (
                    <span
                      key={permission}
                      className="w-12 rounded bg-gray-400 text-white p-1 text-xs"
                    >
                      {permission}
                    </span>
                  );
                })}
              </div>
            ),
          },
        ]}
        data={async (query) => {
          const res = (await usersService({
            page: query.page + 1,
            query: query.search
          })) as UsersResponseType;

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "manage_accounts",
            tooltip: "Administrar Usuario",
            onClick: (_, rowData: UserType) => onEditUserHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Agregar Usuario",
            isFreeAction: true,
            onClick: onNewUserHanlder,
          },
        ]}
      />
    </div>
  );
};

export default UserList;
