import * as React from 'react';

import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import { PlaceGroup } from 'types/PlaceGroup';
import { createPlaceGroupService, updatePlaceGroupService } from 'services/PlaceGroupService';


export interface PlaceGroupManagementModalProps {
  placeGroup?: PlaceGroup,
}

const PlaceGroupManagementModal: React.FC<PlaceGroupManagementModalProps & ModalProps> =
  ({ onDismiss, onSubmit, placeGroup }) => {
    const submitButtonRef = React.useRef<HTMLInputElement>();
    const [state, setState] = React.useState<Pick<PlaceGroup, 'uuid' | 'name' | 'code' | 'active'>>({
      uuid: placeGroup?.uuid || '',
      name: placeGroup?.name || '',
      code: placeGroup?.code || '',
      active: placeGroup?.active || false
    });

    const onSubmitHandler = async () => {
      let res;

      if (!placeGroup) {
        res = await createPlaceGroupService({
          name: state.name,
          active: state.active,
          code: state.code,
        });
      } else {
        res = await updatePlaceGroupService({
          uuid: state.uuid,
          name: state.name,
          active: state.active,
          code: state.code,
        });
      }

      onSubmit(res);
    }

    const onInputChange = (e: React.SyntheticEvent) => {
      const data = e.currentTarget as HTMLInputElement;
      const { name, value } = data;

      setState({
        ...state,
        [name]: value
      });
    }

    return (
      <Modal onDismiss={onDismiss} >
        <ModalHeader>
          {placeGroup ? 'Administrar Grupo' : 'Nuevo Grupo'}
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col w-full">
            <div className="flex flex-col w-full">
              <form className="space-y-6" onSubmit={async e => {
                e.preventDefault();
                await onSubmitHandler();
              }}>

                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="name" className="sr-only">Nombre</label>
                    <input
                      required
                      id="name"
                      name="name"
                      type="text"
                      value={state.name}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Nombre"
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="code" className="sr-only">Codigo</label>
                    <input
                      required
                      id="code"
                      name="code"
                      type="text"
                      value={state.code}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Codigo"
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <label className="flex items-center space-x-3 mt-2">
                  <input
                    type="checkbox"
                    name="active"
                    defaultChecked={state.active}
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                    onClick={() => setState({ ...state, active: !state.active })}
                  />
                  <span className="text-gray-500 font-medium">Activo</span>
                </label>

                <div>
                  <button ref={submitButtonRef} type="submit" className="hidden" />
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => submitButtonRef.current.click()}
          >
            Aceptar
          </button>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={onDismiss}
          >
            Cancelar
          </button>
        </ModalFooter>
      </Modal>
    );
  }

export default PlaceGroupManagementModal;
