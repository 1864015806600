import { gql } from "@apollo/client";
import { authClient } from "libs/AuthClient";
import { LoginServiceType } from "types/AuthType";

const loginService = ({ username, password, placeCode }: LoginServiceType) => {
  return authClient()
    .query({
      query: gql`
        query login($input: LoginInput!) {
          login(input: $input) {
            token
            user {
              username
              rolesPermissions {
                roles
                permissions
              }
            }
            place {
              uuid
              name
              company {
                uuid
                name
              }
            }
          }
        }
      `,
      variables: {
        input: {
          username,
          password,
          placeCode,
        },
      },
    })
    .then(({ data }): Object => {
      const { place, token, user } = data.login;

      // TODO: set permission on user instance.
      return {
        user: {
          username: user.username,
        },
        roles: user.rolesPermissions.roles,
        permissions: user.rolesPermissions.permissions,
        place,
        token,
      };
    });
};

const logoutService = () => {
  return authClient().query({
    query: gql`
      query logout {
        logout
      }
    `,
  });
};

const tokenLoginService = () => {
  return authClient()
    .query({
      query: gql`
        query tokenLogin {
          tokenLogin {
            user {
              username
              rolesPermissions {
                roles
                permissions
              }
            }
            place {
              uuid
              name
              company {
                uuid
                name
              }
            }
          }
        }
      `,
    })
    .then(({ data }): Object => {
      const { place, token, user } = data.tokenLogin;

      return {
        user: {
          username: user.username,
        },
        roles: user.rolesPermissions.roles,
        permissions: user.rolesPermissions.permissions,
        place,
        token,
      };
    })
    .catch((err) => {
      return err;
    });
};

const getRolesPermissionsService = () => {
  return authClient()
    .query({
      query: gql`
        query getRolesPermissions {
          getRolesPermissions {
            roles
            permissions
          }
        }
      `,
    })
    .then(({ data }): Object => {
      const { roles, permissions } = data.getRolesPermissions;

      return {
        roles,
        permissions,
      };
    });
};

export {
  loginService,
  logoutService,
  tokenLoginService,
  getRolesPermissionsService,
};
