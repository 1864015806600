import * as React from "react";
import { DateTime } from "luxon";

import { useModal } from "components/UI/Modal/ModalProvider";
import Table from "components/UI/Table";
import { toast } from "react-toastify";
import ServiceItemManagementModal from "./ServiceItemManagementModal";
import MessageModal from "components/UI/Message/MessageModal";
import { useParams } from "react-router-dom";
import {
  addRemoveServiceItemToServiceService,
  getServiceItemsService,
} from "services/ServiceService";
import { ServiceItemsResponseType, ServiceItemType } from "types/ServiceType";

interface ServiceItemsListProps {}

export const ServiceItemsList: React.FC<ServiceItemsListProps> = () => {
  const params = useParams();
  const messageModal = useModal(MessageModal);
  const [limit, setLimit] = React.useState<number>(25);
  const serviceItemManagementModal = useModal(ServiceItemManagementModal);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewServiceItemHandler = async () => {
    const res = await serviceItemManagementModal({ ...params });

    if (res?.data) {
      toast.success(res?.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onEditServiceItemHandler = async (serviceItem: ServiceItemType) => {
    const res = await serviceItemManagementModal({ ...params, serviceItem });

    if (res?.data) {
      toast.success(res?.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onRemoveServiceItemHandler = async (serviceItem: ServiceItemType) => {
    const res = await messageModal({
      message: `Remover el servicio ${
        serviceItem.item ? serviceItem.item.name : ""
      }`,
      confirm: true,
    });
    if (res?.data) {
      const res2: any = await addRemoveServiceItemToServiceService({
        serviceUUID: params.serviceUUID,
        itemUUID: serviceItem.item?.uuid,
        serviceItemUUID: serviceItem.uuid,
        price: parseFloat(`${serviceItem.price}`),
        add: false,
      });

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de servicios disponibles"
        limit={limit}
        columns={[
          { title: "Nombre", field: "item.name" },
          { title: "Precio", field: "price" },
        ]}
        data={async (query) => {
          const res = (await getServiceItemsService({
            input: {
              uuid: params.serviceUUID,
              page: query.page + 1,
              query: query.search,
            },
          })) as ServiceItemsResponseType;
          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          {
            icon: "edit",
            tooltip: "Editar item del servicio",
            onClick: (_, rowData: ServiceItemType) =>
              onEditServiceItemHandler(rowData),
          },
          {
            icon: "delete",
            tooltip: "Eliminar item del servicio",
            onClick: (_, rowData: ServiceItemType) =>
              onRemoveServiceItemHandler(rowData),
          },
          {
            icon: "add",
            tooltip: "Agregar Servicio",
            isFreeAction: true,
            onClick: onNewServiceItemHandler,
          },
        ]}
      />
    </div>
  );
};

export default ServiceItemsList;
