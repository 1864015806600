import * as React from "react";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";

import Table from "components/UI/Table";
import {
  invoiceReportsService,
  regenerateReportService,
  removeReportService,
} from "services/ReportService";
import { Report, ReportResponseType } from "types/Report";
import { useModal } from "components/UI/Modal/ModalProvider";
import ReportManagementModal from "./ReportManagementModal";
import MessageModal from "components/UI/Message/MessageModal";
import Loading from "components/UI/Modal/Loading";
import { resolveFileUploads } from "libs";

interface InvoiceReportListProps {}

const InvoiceReportList: React.FC<InvoiceReportListProps> = () => {
  const modalButtonRef = React.useRef<HTMLButtonElement>(null);
  const loadingModal = useModal(Loading);

  const history = useHistory();
  const messageModal = useModal(MessageModal);
  const generateReportModal = useModal(ReportManagementModal);
  const [limit, setLimit] = React.useState<number>(25);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onGenerateReportHandler = async () => {
    const res = await generateReportModal();
    if (res?.data) {
      toast.success(res.data.message);
      history.push(res.data.url);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onRemoveReportHandler = async (report: Report) => {
    const res = await messageModal({
      message: `Remover reporte ${report.placeGroup.name}?`,
      confirm: true,
    });
    if (res?.data) {
      const res2: any = await removeReportService({ uuid: report.uuid });

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  const openReport = (report: Report) => {
    window.open(resolveFileUploads(report.documentUrl), "_blank");
  };

  const regeneratePDF = async (report: Report) => {
    const res = await messageModal({
      message: `Regenerar reporte ${report.placeGroup.name}?`,
      confirm: true,
    });
    if (res?.data) {
      loadingModal({ modalButtonRef, message: "Regenerando reporte" });
      const res2: any = await regenerateReportService({ uuid: report.uuid });

      modalButtonRef.current.click();

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  return (
    <div key={uiTimestamp} className="flex flex-col h-full w-full">
      <Table
        title="Lista de facturación"
        limit={limit}
        columns={[
          { title: "Grupo", field: "placeGroup.name" },
          {
            title: "Monto",
            render: (rowData: Report) => (
              <NumberFormat
                value={rowData.amount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            ),
          },
          {
            title: "Fecha de reporte",
            render: (rowData: Report) => (
              <div className="flex flex-row space-x-2">
                <span>
                  {DateTime.fromISO(rowData.fromDate).toFormat("dd/MM/yyyy")}
                </span>
                <span>-</span>
                <span>
                  {DateTime.fromISO(rowData.toDate).toFormat("dd/MM/yyyy")}
                </span>
              </div>
            ),
          },
          { title: "Creador", field: "user" },
          {
            title: "Creación",
            render: (rowData: Report) => (
              <span>
                {DateTime.fromISO(rowData.createdAt).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}
              </span>
            ),
          },
          // {
          //   title: 'Pagado',
          //   render: (rowData: Report) => (<span>{rowData.paid ? 'SI' : 'NO'}</span>)
          // },
          // {
          //   title: 'Enviado',
          //   render: (rowData: Report) => (<span>{rowData.sent ? 'SI' : 'NO'}</span>)
          // }
        ]}
        data={async (query) => {
          const res = (await invoiceReportsService({
            input: { page: query.page + 1, query: query.search },
          })) as ReportResponseType;

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "pageview",
            tooltip: "Previsualizar Reporte",
            onClick: (_, rowData: Report) => openReport(rowData),
          }),
          (_) => ({
            icon: "picture_as_pdf",
            tooltip: "Regenerar PDF",
            onClick: (_, rowData: Report) => regeneratePDF(rowData),
          }),
          // _ => ({
          //   icon: 'confirmation_number',
          //   tooltip: 'Registrar Pago',
          //   onClick: (_, rowData: Report) => console.log(rowData),
          // }),
          // _ => ({
          //   icon: 'send',
          //   tooltip: 'Enviar Reporte',
          //   onClick: (_, rowData: Report) => console.log(rowData),
          // }),
          (_) => ({
            icon: "delete",
            tooltip: "Eliminar Reporte",
            onClick: (_, rowData: Report) => onRemoveReportHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Generar reporte",
            isFreeAction: true,
            onClick: onGenerateReportHandler,
          },
        ]}
      />
    </div>
  );
};

export default InvoiceReportList;
