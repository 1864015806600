import * as React from 'react';
import Select from 'react-select';
import cronstrue from 'cronstrue/i18n';

import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import { CronJob } from 'types/CronJob';
import { CRONJOB_STATUS } from 'constant/CronJob';
import { createCronjobService, updateCronjobService } from 'services/CronJobService';


export interface UserManagementModalProps {
  cronJob?: CronJob
}

const UserManagementModal: React.FC<UserManagementModalProps & ModalProps> = ({ onDismiss, onSubmit, cronJob }) => {
  const submitButtonRef = React.useRef<HTMLInputElement>();
  const [selectedStatus, setSelectedStatus] = React.useState<{ label: string, value: CRONJOB_STATUS }>(
    cronJob ? { label: cronJob.status, value: cronJob.status } : null
  );
  const [state, setState] = React.useState<CronJob>({
    uuid: cronJob?.uuid || '',
    name: cronJob?.name || '',
    key: cronJob?.key || '',
    status: cronJob?.status || null,
    scheduleString: cronJob?.scheduleString || '',
    timeZone: cronJob?.timeZone || ''
  });

  React.useEffect(() => {

  }, []);

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    setState({
      ...state,
      [name]: value
    });
  }

  const onSubmitHandler = async () => {
    let res;

    if (cronJob) {
      res = await updateCronjobService({
        uuid: state.uuid,
        name: state.name,
        key: state.key,
        scheduleString: state.scheduleString,
        timeZone: state.timeZone,
        status: selectedStatus.value
      });
    } else {
      res = await createCronjobService({
        name: state.name,
        key: state.key,
        scheduleString: state.scheduleString,
        timeZone: state.timeZone,
        status: selectedStatus.value
      });
    }

    onSubmit(res);
  }

  const getCronText = (value: string) => {
    try {
      return cronstrue.toString(value, { locale: "es" });
    } catch (error) {
      return null;
    }
  }


  return (
    <Modal onDismiss={onDismiss} >
      <ModalHeader>
        {cronJob ? "Editar Cron Job" : 'Nuevo Cron Job'}
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            <form className="space-y-6" onSubmit={async e => {
              e.preventDefault();
              await onSubmitHandler();
            }}>

              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="name" className="sr-only">Nombre del CronJob</label>
                  <input
                    required
                    id="name"
                    name="name"
                    type="text"
                    value={state.name}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Nombre del CronJob"
                    onChange={onInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="key" className="sr-only">Llave de Busqueda</label>
                  <input
                    required
                    id="key"
                    name="key"
                    type="text"
                    value={state.key}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Llave de Busqueda (ex: dailyReports)"
                    onChange={onInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="scheduleString" className="sr-only">Cadena de Tarea</label>
                  <input
                    required
                    id="scheduleString"
                    name="scheduleString"
                    type="text"
                    value={state.scheduleString}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Cadena de Tarea (ex: */10 * * * * *)"
                    onChange={onInputChange}
                  />
                  <div className="text-xs p-2 text-gray-500">
                    Ejecutar {getCronText(state.scheduleString)}
                  </div>
                </div>

                <div>
                  <label htmlFor="timeZone" className="sr-only">Zona Horaria</label>
                  <input
                    required
                    id="timeZone"
                    name="timeZone"
                    type="text"
                    value={state.timeZone}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Zona Horaria (ex: America/Tijuana)"
                    onChange={onInputChange}
                  />
                </div>
              </div>

              <div className="relative h-24">
                <label htmlFor="status" className="sr-only">Estado</label>
                <Select
                  id="status"
                  name="status"
                  placeholder="Estado del Cronjob"
                  className="basic-multi-seslect"
                  classNamePrefix="select"
                  options={Object.values(CRONJOB_STATUS).map(v => ({ label: v, value: v }))}
                  defaultValue={selectedStatus}
                  value={selectedStatus}
                  onChange={status => setSelectedStatus(status as any)}
                />
                {!selectedStatus && (
                  <input
                    required
                    name="item"
                    className="outline-none focus:outline-none absolute"
                    style={{ height: 1, opacity: 0 }}
                  />
                )}
              </div>

              <div>
                <button ref={submitButtonRef} type="submit" className="hidden" />
              </div>

            </form>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => submitButtonRef.current.click()}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onDismiss}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default UserManagementModal;
