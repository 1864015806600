import * as React from 'react';
import AsyncSelect from 'react-select/async';

import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import { ItemType } from 'types/ItemType';
import { getItemsQueryService } from 'services/ItemService';
import { ServiceItemType } from 'types/ServiceType';
import { addRemoveServiceItemToServiceService } from 'services/ServiceService';


export interface ServiceItemManagementModalProps {
  serviceUUID: string,
  serviceItem?: ServiceItemType
}

const ServiceItemManagementModal: React.FC<ServiceItemManagementModalProps & ModalProps> =
  ({ onDismiss, onSubmit, serviceUUID, serviceItem }) => {
    const submitButtonRef = React.useRef<HTMLInputElement>();
    const [selectedItem, setselectedItem] = React.useState<{ label: string, value: ItemType }>(
      serviceItem?.item ? { label: serviceItem.item.name, value: serviceItem.item } : null
    );
    const [state, setState] = React.useState<ServiceItemType>({
      uuid: serviceItem?.uuid || '',
      item: serviceItem?.item || null,
      price: serviceItem?.price || null
    });

    const onInputChange = (e: React.SyntheticEvent) => {
      const data = e.currentTarget as HTMLInputElement;
      const { name, value } = data;

      setState({
        ...state,
        [name]: value
      });
    }

    const onSubmitHandler = async () => {
      let res;

      res = await addRemoveServiceItemToServiceService({
        serviceUUID,
        itemUUID: selectedItem?.value?.uuid || state.item.uuid,
        price: parseFloat(`${state.price}`),
        add: true
      });

      onSubmit(res);
    }

    const loadItems = (inputValue: string) => {
      return getItemsQueryService({ query: inputValue })
        .then((items: ItemType[]) => {
          return items.map(item => ({
            label: item.name,
            value: item
          }))
        })
    };

    return (
      <Modal onDismiss={onDismiss} >
        <ModalHeader>
          {serviceItem ? "Editar item en servicio" : 'Nuevo item servicio'}
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col w-full">
            <div className="flex flex-col w-full">
              <form className="space-y-6" onSubmit={async e => {
                e.preventDefault();
                await onSubmitHandler();
              }}>
                <div className="relative">
                  <label htmlFor="item" className="sr-only">Productos</label>
                  <AsyncSelect
                    id="item"
                    name="item"
                    isDisabled={!!serviceItem}
                    placeholder="Producto"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={selectedItem}
                    loadOptions={loadItems}
                    onChange={item => setselectedItem(item as any)}
                  />
                  {!selectedItem && (
                    <input
                      required
                      name="item"
                      className="outline-none focus:outline-none absolute"
                      style={{ height: 1, opacity: 0 }}
                    />
                  )}
                </div>
                <div>
                  <label htmlFor="price" className="sr-only">Precio</label>
                  <input
                    required
                    id="price"
                    name="price"
                    type="number"
                    value={state.price || ''}
                    className="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Precio"
                    onChange={onInputChange}
                  />
                </div>

                <div>
                  <button ref={submitButtonRef} type="submit" className="hidden" />
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => submitButtonRef.current.click()}
          >
            Aceptar
          </button>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={onDismiss}
          >
            Cancelar
          </button>
        </ModalFooter>
      </Modal>
    );
  }

export default ServiceItemManagementModal;
