import { gql } from "@apollo/client";
import { deepCopy } from "libs";
import { authClient } from "libs/AuthClient";
import { PaginationParams } from "types/PaginationType";
import { ReportCreate, ReportRemove, ReportUpdate } from "types/Report";

const generateReportService = (generateReport: ReportCreate) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation generateReport($input: GenerateReportInput!) {
          generateReport(input: $input)
        }
      `,
      variables: {
        input: {
          ...generateReport,
        },
      },
    })
    .then(({ data }): Object => {
      const generateReportData = data.generateReport;

      return {
        data: generateReportData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const manualReportsService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query manualReports($input: BasePaginationInput!) {
          manualReports(input: $input) {
            docs {
              ... on Report {
                uuid
                service {
                  uuid
                  name
                }
                amount
                documentUrl
                documentUrlExtra
                user
                paid
                sent
                type
                fromDate
                toDate
                createdAt
              }
            }
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            limit
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const manualReportsData = data.manualReports;

      return {
        ...manualReportsData,
      };
    });
};

const billingReportsService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query billingReports($input: BasePaginationInput!) {
          billingReports(input: $input) {
            docs {
              ... on Report {
                uuid
                placeGroup {
                  uuid
                  name
                }
                orderCount
                documentUrl
                documentUrlExtra
                user
                paid
                sent
                type
                fromDate
                toDate
                createdAt
              }
            }
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            limit
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const billingReportsData = data.billingReports;

      return {
        ...billingReportsData,
      };
    });
};

const invoiceReportsService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query invoiceReports($input: BasePaginationInput!) {
          invoiceReports(input: $input) {
            docs {
              ... on Report {
                uuid
                placeGroup {
                  uuid
                  name
                }
                amount
                documentUrl
                documentUrlExtra
                user
                paid
                sent
                type
                fromDate
                toDate
                createdAt
              }
            }
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            limit
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const invoiceReportsData = data.invoiceReports;

      return {
        ...invoiceReportsData,
      };
    });
};

const regenerateReportService = ({ uuid }: ReportRemove) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation regenerateReport($uuid: String!) {
          regenerateReport(uuid: $uuid)
        }
      `,
      variables: {
        uuid,
      },
    })
    .then(({ data }): Object => {
      const regenerateReportData = data.regenerateReport;

      return {
        data: regenerateReportData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const sendReportService = ({ uuid }: ReportRemove) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation sendReport($uuid: String!) {
          sendReport(uuid: $uuid)
        }
      `,
      variables: {
        uuid,
      },
    })
    .then(({ data }): Object => {
      const sendReportData = data.sendReport;

      return {
        data: sendReportData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const updateReportService = (reportUpdate: ReportUpdate) => {
  return authClient()
    .mutate({
      context: {
        hasUpload: true,
      },
      mutation: gql`
        mutation updateReport($input: UpdateReportInput!) {
          updateReport(input: $input)
        }
      `,
      variables: {
        input: {
          ...reportUpdate,
        },
      },
    })
    .then(({ data }): Object => {
      const updateReportData = data.updateReport;

      return {
        data: updateReportData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const removeReportService = ({ uuid }: ReportRemove) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation removeReport($uuid: String!) {
          removeReport(uuid: $uuid)
        }
      `,
      variables: {
        uuid,
      },
    })
    .then(({ data }): Object => {
      const removeReportData = data.removeReport;

      return {
        data: removeReportData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

export {
  updateReportService,
  regenerateReportService,
  generateReportService,
  manualReportsService,
  billingReportsService,
  invoiceReportsService,
  removeReportService,
  sendReportService,
};
