import { gql } from "@apollo/client";
import { deepCopy } from "libs";
import { authClient } from "libs/AuthClient";
import { OrderResponseType, OrderType } from "types/OrderType";
import { PaginationParams } from "types/PaginationType";

const ordersService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query orders($input: BasePaginationInput!) {
          orders(input: $input) {
            docs {
              ... on Order {
                uuid
                reference
                paymentType
                orderNumber
                status
                amount
                currency
                documentUrl
                place {
                  name
                }
                user {
                  username
                }
                device {
                  name
                  code
                }
                paidAt
                refItems {
                  serviceName
                  cardComission
                  serviceComission
                  quantity
                  price
                }
              }
            }
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            limit
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const ordersData = data.orders as OrderResponseType;

      return {
        ...ordersData,
      };
    });
};

const regenerateTicketService = ({ uuid }: Pick<OrderType, "uuid">) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation regenerateOrderTicket($uuid: String!) {
          regenerateOrderTicket(uuid: $uuid)
        }
      `,
      variables: {
        uuid,
      },
    })
    .then(({ data }): Object => {
      const regenerateOrderTicketData = data.regenerateOrderTicket;

      return {
        data: regenerateOrderTicketData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

export { ordersService, regenerateTicketService };
