import * as React from 'react';
import AsyncSelect from 'react-select/async';

import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import { ServiceType } from 'types/ServiceType';
import { getServiceQueryByPlaceService, getServiceQueryService } from 'services/ServiceService';
import { addServiceToDeviceService } from 'services/DeviceService';


export interface DeviceServiceManagementModalProps {
  deviceUUID: string,
}

const DeviceServiceManagementModal: React.FC<DeviceServiceManagementModalProps & ModalProps> =
  ({ onDismiss, onSubmit, deviceUUID }) => {
    const submitButtonRef = React.useRef<HTMLInputElement>();
    const [selectedService, setSelectedService] = React.useState<{ label: string, value: ServiceType }>(null);

    const onSubmitHandler = async () => {
      const res = await addServiceToDeviceService({
        deviceUUID,
        serviceUUID: selectedService.value.uuid,
      });

      onSubmit(res);
    }

    const loadIServices = (inputValue: string) => {
      return getServiceQueryByPlaceService({ query: inputValue })
        .then((services: ServiceType[]) => {
          return services.map(service => ({
            label: service.name,
            value: service
          }))
        })
    };

    return (
      <Modal onDismiss={onDismiss}>
        <ModalHeader>
          Nuevo servicio en dispositivo
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col w-full" style={{ height: 300 }}>
            <div className="flex flex-col w-full">
              <form className="space-y-6" onSubmit={async e => {
                e.preventDefault();
                await onSubmitHandler();
              }}>
                <div className="relative">
                  <label htmlFor="item" className="sr-only">Servicios</label>
                  <AsyncSelect
                    id="item"
                    name="item"
                    placeholder="Servicio"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={selectedService}
                    loadOptions={loadIServices}
                    onChange={item => setSelectedService(item as any)}
                  />
                  {!selectedService && (
                    <input
                      required
                      name="item"
                      className="outline-none focus:outline-none absolute"
                      style={{ height: 1, opacity: 0 }}
                    />
                  )}
                </div>

                <div>
                  <button ref={submitButtonRef} type="submit" className="hidden" />
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => submitButtonRef.current.click()}
          >
            Aceptar
          </button>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={onDismiss}
          >
            Cancelar
          </button>
        </ModalFooter>
      </Modal>
    );
  }

export default DeviceServiceManagementModal;
