import { gql } from "@apollo/client";
import { deepCopy } from "libs";
import { authClient } from "libs/AuthClient";
import {
  AddServiceDeviceType,
  AddUserDeviceType,
  DeviceResponseType,
  DeviceType,
  RemoveServiceFromDeviceType,
  RemoveUserFromDeviceType,
} from "types/DeviceType";
import { PaginationParams } from "types/PaginationType";
import { ServiceResponseType } from "types/ServiceType";
import { UsersResponseType } from "types/UserType";

const createDeviceService = (
  createDeviceData: Pick<DeviceType, "name" | "code" | "type" | "active" | 'settings'>
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation createDevice($input: CreateDeviceInput!) {
          createDevice(input: $input)
        }
      `,
      variables: {
        input: {
          ...createDeviceData,
        },
      },
    })
    .then(({ data }): Object => {
      const deviceData = data.createDevice;

      return {
        data: deviceData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const updateDeviceService = (
  updateDeviceData: Pick<DeviceType, "uuid" | "name" | "active" | 'settings'>
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation updateDevice($input: UpdateDeviceInput!) {
          updateDevice(input: $input)
        }
      `,
      variables: {
        input: { ...updateDeviceData },
      },
    })
    .then(({ data }): Object => {
      const deviceData = data.updateDevice;

      return {
        data: deviceData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const getDevicesService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query getdevices($input: BasePaginationInput!) {
          devices(input: $input) {
            limit
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            docs {
              ... on Device {
                uuid
                name
                code
                type
                active
                settings {
                  paymentMethod
                }
              }
            }
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const deviceServiceData = data.devices as DeviceResponseType;

      return {
        ...deviceServiceData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const getDevicesServicesService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query deviceServices($input: BasePaginationInput!) {
          deviceServices(input: $input) {
            limit
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            docs {
              ... on Service {
                uuid
                name
                type
              }
            }
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const deviceServiceData = data.deviceServices as ServiceResponseType;

      return {
        ...deviceServiceData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const getDevicesUsersService = (deviceUSersData: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query deviceUsers($input: BasePaginationInput!) {
          deviceUsers(input: $input) {
            limit
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            docs {
              ... on User {
                uuid
                username
                active
              }
            }
          }
        }
      `,
      variables: {
        ...deviceUSersData,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const deviceServiceData = data.deviceUsers as UsersResponseType;

      return {
        ...deviceServiceData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const addUserToDeviceService = (addUserToDeviceData: AddUserDeviceType) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation addUserToDevice($input: AddUserToDeviceInput!) {
          addUserToDevice(input: $input)
        }
      `,
      variables: {
        input: { ...addUserToDeviceData },
      },
    })
    .then(({ data }): Object => {
      const deviceData = data.addUserToDevice;

      return {
        data: deviceData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const removeUserFromDeviceService = (
  removeUserFromDeviceData: RemoveUserFromDeviceType
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation removeUserFromDevice($input: RemoveUserFromDeviceInput!) {
          removeUserFromDevice(input: $input)
        }
      `,
      variables: {
        input: {
          ...removeUserFromDeviceData,
        },
      },
    })
    .then(({ data }): Object => {
      const deviceData = data.removeUserFromDevice;

      return {
        data: deviceData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const addServiceToDeviceService = (
  addServiceToDeviceData: AddServiceDeviceType
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation addServiceToDevice($input: AddServiceToDeviceInput!) {
          addServiceToDevice(input: $input)
        }
      `,
      variables: {
        input: {
          ...addServiceToDeviceData,
        },
      },
    })
    .then(({ data }): Object => {
      const deviceData = data.addServiceToDevice;

      return {
        data: deviceData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const removeServiceFromDeviceService = (
  removeServiceFromDeviceData: RemoveServiceFromDeviceType
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation removeServiceFromDevice(
          $input: RemoveServiceFromDeviceInput!
        ) {
          removeServiceFromDevice(input: $input)
        }
      `,
      variables: {
        input: {
          ...removeServiceFromDeviceData,
        },
      },
    })
    .then(({ data }): Object => {
      const deviceData = data.removeServiceFromDevice;

      return {
        data: deviceData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const removeDeviceService = (
  uuid: string
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation removeDevice($uuid: String!) {
          removeDevice(uuid: $uuid)
        }
      `,
      variables: {
        uuid,
      },
    })
    .then(({ data }): Object => {
      const removeDeviceData = data.removeDevice;

      return {
        data: removeDeviceData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

export {
  getDevicesService,
  getDevicesServicesService,
  getDevicesUsersService,
  createDeviceService,
  updateDeviceService,
  addUserToDeviceService,
  removeUserFromDeviceService,
  addServiceToDeviceService,
  removeServiceFromDeviceService,
  removeDeviceService,
};
