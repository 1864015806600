import * as React from 'react';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';

import { REPORT_RANGE_TYPE, REPORT_TYPE } from 'constant/Report';
import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal, Spinner } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import StepNavigator, { Step } from 'components/UI/StepNavigator';

import { generateReportService } from 'services/ReportService';
import { ReportCreate } from 'types/Report';
import { RouteConstants } from 'constant';


interface ReportManagementModalProps {
}

type State = ReportCreate;

enum CurrentStep {
  TYPE = 'type',
  RANGE = 'range',
  DATE = 'date',
}

const reportTypeDef = (type: string) => {
  let def = '';

  switch (type) {
    case REPORT_TYPE.BILLING:
      def = 'Cobranza';
      break;
    case REPORT_TYPE.INVOICE:
      def = 'Facturación';
      break;
    case REPORT_TYPE.COMISSION:
      def = 'Comisiones';
      break;
    default:
      break;
  }

  return def;
}

const reportRangeTypeDef = (type: string) => {
  let def = '';

  switch (type) {
    case REPORT_RANGE_TYPE.DAILY:
      def = 'Diario';
      break;
    case REPORT_RANGE_TYPE.WEEKLY:
      def = 'Semanal';
      break;
    case REPORT_RANGE_TYPE.MONTHLY:
      def = 'Mensual';
    default:
      break;
  }

  return def;
}

const reportRangeTypeDef2 = (type: string) => {
  let def = '';

  switch (type) {
    case REPORT_RANGE_TYPE.DAILY:
      def = 'dia';
      break;
    case REPORT_RANGE_TYPE.WEEKLY:
      def = 'dias de la semana';
      break;
    case REPORT_RANGE_TYPE.MONTHLY:
      def = 'mes';
    default:
      break;
  }

  return def;
}

const ReportManagementModal: React.FC<ReportManagementModalProps & ModalProps> = ({ onDismiss, onSubmit }) => {
  const [fromDate, setFromDate] = React.useState<Date>(DateTime.now().toJSDate());
  const [toDate, setToDate] = React.useState<Date>(null);
  const [currentStep, setCurrentStep] = React.useState<CurrentStep>(CurrentStep.TYPE);
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [steps, setSteps] = React.useState<Step[]>([
    { title: 'Tipo', percentage: 0 },
    { title: 'Rango', percentage: 0 },
    { title: 'Fecha', percentage: 0 },
  ]);
  const [state, setState] = React.useState<State>({
    type: null,
    rangeType: null,
    fromDate: null,
    toDate: null,
  });

  React.useEffect(() => {
    if (state.type) {
      steps[0].percentage = 100;
      steps[1].percentage = 99;
      steps[2].percentage = 0;
    }
    if (state.type && state.rangeType) {
      steps[0].percentage = 100;
      steps[1].percentage = 100;
      steps[2].percentage = 99;
    }
    if (state.type && state.rangeType && state.fromDate && state.toDate) {
      steps[0].percentage = 100;
      steps[1].percentage = 100;
      steps[2].percentage = 100;
    }

  }, [state, steps]);

  const canGenerate = () => {
    let monthDateComplete = false;

    if (currentStep === CurrentStep.DATE) {
      if (state.rangeType === REPORT_RANGE_TYPE.WEEKLY && fromDate && toDate) {
        monthDateComplete = true;
      } else if (state.rangeType === REPORT_RANGE_TYPE.MONTHLY && fromDate) {
        monthDateComplete = true;
      } else if (state.rangeType === REPORT_RANGE_TYPE.DAILY && fromDate) {
        monthDateComplete = true;
      }
    }

    return monthDateComplete;
  }

  const canGoNextHanlder = () => {
    return currentStep === CurrentStep.TYPE && state.type ||
      currentStep === CurrentStep.RANGE && state.rangeType ||
      canGenerate();
  }

  const goNextStepHandler = ({ next }: { next: boolean }) => {
    switch (currentStep) {
      case CurrentStep.TYPE:
        setCurrentStep(CurrentStep.RANGE);
        break;
      case CurrentStep.RANGE:
        if (!next) {
          steps[0].percentage = 100;
          steps[1].percentage = 0;
          steps[2].percentage = 0;
        }

        setCurrentStep(next ? CurrentStep.DATE : CurrentStep.TYPE);
        break;
      case CurrentStep.DATE:
        if (!next) {
          steps[0].percentage = 100;
          steps[1].percentage = 100;
          steps[2].percentage = 0;
        }

        setCurrentStep(next ? CurrentStep.DATE : CurrentStep.RANGE);
        break;
      default:
        break;
    }

    setSteps(steps);
  }

  const onTypeSelectHandler = (type: any) => {
    steps[0].percentage = state.type === type ? 0 : 100;
    setState({ ...state, type: state.type === type ? null : type });
  }

  const onRangeTypeSelectHandler = (rangeType: any) => {
    steps[1].percentage = state.rangeType === rangeType ? 99 : 100;
    setState({ ...state, rangeType: state.rangeType === rangeType ? null : rangeType });
  }

  const onChangeDateHandler = (date: any) => {
    if (state.rangeType === REPORT_RANGE_TYPE.WEEKLY) {
      const [start, end] = date;
      setFromDate(start);
      setToDate(end);
    } else {
      setFromDate(DateTime.fromJSDate(date).toJSDate());
    }
  }

  const generateReport = async () => {
    setRequesting(true);
    const result = await generateReportService({
      toDate,
      fromDate,
      type: state.type,
      rangeType: state.rangeType,
    });

    let url: string;

    if (state.type === REPORT_TYPE.BILLING) {
      url = RouteConstants.BILLING_REPORT.path;
    } else if (state.type === REPORT_TYPE.INVOICE) {
      url = RouteConstants.INVOICE_REPORT.path;
    }

    setRequesting(false);
    onSubmit({
      data:
      {
        message: result,
        url
      }
    });
  }

  const datePickerSttings = () => {
    if (state.rangeType === REPORT_RANGE_TYPE.WEEKLY) {
      return {
        selected: fromDate,
        startDate: fromDate,
        endDate: toDate,
        selectsRange: true,
        inline: true
      };
    } else if (state.rangeType === REPORT_RANGE_TYPE.MONTHLY) {
      return {
        dateFormat: "MM/yyyy",
        selected: fromDate,
        showMonthYearPicker: true,
        showFullMonthYearPicker: true
      };
    } else {
      return {
        selected: fromDate,
      };
    }
  }

  const itemsTypes = Object.values(REPORT_TYPE)
    .filter(i => i !== REPORT_TYPE.CUT && i !== REPORT_TYPE.MANUAL);

  return (
    <Modal>
      <ModalHeader showCloseButon>
        Generar Reporte
      </ModalHeader>
      <ModalBody>
        {!requesting ? (
          <div className="flex flex-col w-full h-96">

            <StepNavigator
              steps={steps}
            />

            {currentStep === CurrentStep.TYPE && (
              <div className="flex flex-col w-full text-gray-500 space-y-6 text-center mt-6">
                <span className="text-xl">Tipo de reporte</span>
                <div className={`w-full grid grid-rows-${itemsTypes.length} grid-flow-col gap-2`}>
                  {itemsTypes.map((type: any, index: number) => {
                      return (
                        <div
                          key={`type-${index}`}
                          className={`flex items-center ${state.type === type ? 'text-white bg-gray-900 border-gray-800' : ''} justify-center border rounded border-gray-300 h-24 hover:text-white hover:bg-gray-900 hover:border-gray-800 cursor-pointer`}
                          onClick={() => onTypeSelectHandler(type)}
                        >
                          {reportTypeDef(type)}
                        </div>
                      )
                    })}
                </div>
              </div>
            )}

            {currentStep === CurrentStep.RANGE && (
              <div className="flex flex-col w-full text-gray-500 space-y-6 text-center mt-6">
                <span className="text-xl">Rango de días</span>
                <div className={`w-full grid grid-rows-${Object.keys(REPORT_RANGE_TYPE).length} grid-flow-col gap-2`}>
                  {Object.values(REPORT_RANGE_TYPE).map((rangeType: any, index: number) => {
                    return (
                      <div
                        key={`typeRange-${index}`}
                        className={`flex items-center ${state.rangeType === rangeType ? 'text-white bg-gray-900 border-gray-800' : ''} justify-center border rounded border-gray-300 h-24 hover:text-white hover:bg-gray-900 hover:border-gray-800 cursor-pointer`}
                        onClick={() => onRangeTypeSelectHandler(rangeType)}
                      >
                        {reportRangeTypeDef(rangeType)}
                      </div>
                    )
                  })}
                </div>
              </div>
            )}

            {currentStep === CurrentStep.DATE && (
              <div className="flex flex-col w-full text-gray-500 space-y-6 text-center mt-6">
                <span className="text-xl">{`Seleccionar ${reportRangeTypeDef2(state.rangeType)}`}</span>
                <DatePicker
                  className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:border-pagocheck-red"
                  onChange={onChangeDateHandler}
                  isClearable
                  {...datePickerSttings()}
                />
              </div>
            )}

          </div>
        ) : (
          <div className="flex w-full h-full justify-center items-center">
            <Spinner text="Generando..." className="h-24 w-24 text-pagocheck-red" />
          </div>
        )}
      </ModalBody>
      {!requesting && (
        <ModalFooter>
          <button
            type="button"
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${canGoNextHanlder() ? 'bg-red-600 hover:bg-red-700' : 'bg-gray-600 hover:bg-gray-700'} text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm`}
            onClick={() => canGoNextHanlder() ? canGenerate() ? generateReport() : goNextStepHandler({ next: true }) : null}
          >
            {currentStep !== CurrentStep.DATE ? 'Siguiente' : 'Generar'}
          </button>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => goNextStepHandler({ next: false })}
          >
            Atrás
          </button>
        </ModalFooter>
      )}
    </Modal>
  );
}

export default ReportManagementModal;
