export enum AUTH_ACTION {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAIL = 'LOGIN_FAIL',
  LOGOUT = 'LOGOUT',
};

export enum ROLES {
  USER = 'user',
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  SUPERVISOR = 'supervisor',
  AUDIT = 'audit',
};

export enum PERMISSIONS {
  POS = 'pos',
  KIOSK = 'kiosk',
  TOKEN = 'token'
};