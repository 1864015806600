import * as React from "react";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

import { useModal } from "components/UI/Modal/ModalProvider";
import Table from "components/UI/Table";
import ParamManagementModal from "./ParamManagementModal";
import MessageModal from "components/UI/Message/MessageModal";
import {
  removeSettingParamService,
  settingParamsService,
} from "services/SettingService";
import { ParamType, SettingResponseType } from "types/SettingType";
import { useParams } from "react-router-dom";

interface ParamsListProps {}

const ParamsList: React.FC<ParamsListProps> = () => {
  const params = useParams();
  const messageModal = useModal(MessageModal);
  const [limit, setLimit] = React.useState<number>(25);
  const paramManagementModal = useModal(ParamManagementModal);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewParamHanlder = async () => {
    const res = await paramManagementModal({ settingUUID: params.settingUUID });

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onEditParamHandler = async (param: ParamType) => {
    const res = await paramManagementModal({
      param,
      settingUUID: params.settingUUID,
    });

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onRemoveParamHandler = async (param: ParamType) => {
    const res = await messageModal({
      message: `Remover parámetro ${param.key}?`,
      confirm: true,
    });
    if (res?.data) {
      const res2: any = await removeSettingParamService({
        uuid: param.uuid,
        settingUUID: params.settingUUID,
      });

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de parámetros"
        limit={limit}
        columns={[
          { title: "Llave", field: "key" },
          {
            title: "Valor",
            field: "value",
            cellStyle: {
              maxWidth: 400,
              minWidth: 400,
              textOverflow: "ellipsis",
              overflowX: "hidden",
            },
          },
          { title: "Detalles", field: "details" },
          { title: "Tipo", field: "type" },
        ]}
        data={async (query) => {
          const res = (await settingParamsService({
            input: {
              page: query.page + 1,
              uuid: params.settingUUID,
              query: query.search,
            },
          })) as SettingResponseType;

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "edit",
            tooltip: "Editar parámetro",
            onClick: (_, rowData: ParamType) => onEditParamHandler(rowData),
          }),
          (_) => ({
            icon: "delete",
            tooltip: "Eliminar parámetro",
            onClick: (_, rowData: ParamType) => onRemoveParamHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Agregar parámetro",
            isFreeAction: true,
            onClick: onNewParamHanlder,
          },
        ]}
      />
    </div>
  );
};

export default ParamsList;
