import * as React from "react";
import Select from "react-select";

import { ModalProps } from "components/UI/Modal/ModalProvider";
import { Modal } from "components/UI";
import ModalBody from "components/UI/Modal/ModalBody";
import ModalFooter from "components/UI/Modal/ModalFooter";
import ModalHeader from "components/UI/Modal/ModalHeader";
import {
  createWarehouseService,
  updateWarehouseService,
} from "services/WarehouseService";
import { ServiceType } from "types/ServiceType";
import { SERVICE_TYPE } from "constant/ServiceConstants";
import {
  createServiceService,
  updateServiceService,
} from "services/ServiceService";
import { DeviceType, DEVICE_PAYMENT_METHOD } from "types/DeviceType";
import { DEVICE_TYPE } from "constant";
import {
  createDeviceService,
  updateDeviceService,
} from "services/DeviceService";

export interface DeviceManagementModalProps {
  device?: DeviceType;
}

const DeviceManagementModal: React.FC<
  DeviceManagementModalProps & ModalProps
> = ({ onDismiss, onSubmit, device }) => {
  const submitButtonRef = React.useRef<HTMLInputElement>();
  const [selectedType, setSelectedType] = React.useState<{
    label: string;
    value: DEVICE_TYPE;
  }>(device ? { label: device.type, value: device.type } : null);
  const [state, setState] = React.useState<DeviceType>({
    uuid: device?.uuid || "",
    name: device?.name || "",
    code: device?.code || "",
    active: device?.active || false,
    type: device?.type || null,
    settings: device?.settings || null,
  });

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    setState({
      ...state,
      [name]: value,
    });
  };

  const onPaymentMethodSelect = (payments: {label: string, value: DEVICE_PAYMENT_METHOD }[]) => {
    setState({
      ...state,
      settings: {
        paymentMethod: payments.map(i => i.value)
      }
    });
  }

  const onSubmitHandler = async () => {
    let res;
    if (!device) {
      res = await createDeviceService({
        name: state.name,
        code: state.code,
        active: state.active,
        type: selectedType.value,
        settings: state.settings
      });
    } else {
      res = await updateDeviceService({
        uuid: state.uuid,
        name: state.name,
        active: state.active,
        settings: state.settings
      });
    }

    onSubmit(res);
  };

  return (
    <Modal onDismiss={onDismiss}>
      <ModalHeader>
        {device ? "Editar dispositivo" : "Nuevo dispositivo"}
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full h-auto">
          <div className="flex flex-col w-full">
            <form
              className="space-y-6"
              onSubmit={async (e) => {
                e.preventDefault();
                await onSubmitHandler();
              }}
            >
              <div>
                <label htmlFor="name" className="sr-only">
                  Nombre de dispositivo
                </label>
                <input
                  required
                  id="name"
                  name="name"
                  type="text"
                  value={state.name}
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                  placeholder="Nombre del dispositivo"
                  onChange={onInputChange}
                />
              </div>
              <div>
                <label htmlFor="code" className="sr-only">
                  Codigo de dispositivo
                </label>
                <input
                  required
                  id="code"
                  name="code"
                  type="text"
                  value={state.code}
                  disabled={!!device?.type}
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                  placeholder="Codigo de dispositivo"
                  onChange={onInputChange}
                />
              </div>
              <div className="relative">
                <label htmlFor="item" className="sr-only">
                  Tipo
                </label>
                <Select
                  id="item"
                  name="item"
                  isDisabled={!!device?.type}
                  placeholder="Tipo"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={Object.values(DEVICE_TYPE).map((v) => ({
                    label: v,
                    value: v,
                  }))}
                  defaultValue={selectedType}
                  value={selectedType}
                  onChange={(type) => setSelectedType(type as any)}
                />
                {!selectedType && (
                  <input
                    required
                    name="item"
                    className="outline-none focus:outline-none absolute"
                    style={{ height: 1, opacity: 0 }}
                  />
                )}
              </div>
              <div className="relative">
                <label htmlFor="paymentMethod" className="sr-only">
                  Metódos de pago
                </label>
                <Select
                  isMulti
                  id="paymentMethod"
                  placeholder="Metódos de pago"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={[]}
                  value={state?.settings?.paymentMethod?.map(i => ({
                    label: i,
                    value: i
                  })) ?? []}
                  options={Object.values(DEVICE_PAYMENT_METHOD).map((v) => ({
                    label: v,
                    value: v,
                  }))}
                  onChange={(paymentMethod) => onPaymentMethodSelect(paymentMethod as any)}
                />
                {!selectedType && (
                  <input
                    required
                    name="item"
                    className="outline-none focus:outline-none absolute"
                    style={{ height: 1, opacity: 0 }}
                  />
                )}
              </div>
              <div>
                <label className="flex items-center space-x-3 mt-2 mb-24 sm:mb-34">
                  <input
                    type="checkbox"
                    name="active"
                    defaultChecked={state.active}
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                    onClick={() =>
                      setState({ ...state, active: !state.active })
                    }
                  />
                  <span className="text-gray-500 font-medium">Activo</span>
                </label>
              </div>
              <div>
                <button
                  ref={submitButtonRef}
                  type="submit"
                  className="hidden"
                />
              </div>
            </form>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => submitButtonRef.current.click()}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onDismiss}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default DeviceManagementModal;
