export enum REPORT_TYPE {
  BILLING = 'billing',
  INVOICE = 'invoice',
  COMISSION = 'comission',
  MANUAL = 'manual',
  CUT = 'cut'
};

export enum REPORT_RANGE_TYPE {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly'
};
