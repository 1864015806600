import * as React from 'react';
import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal, Spinner } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalHeader from 'components/UI/Modal/ModalHeader';


export interface LoadingProps {
  message?: string,
  modalButtonRef?: React.LegacyRef<HTMLButtonElement>
}

const Loading: React.FC<LoadingProps & ModalProps> = ({ onSubmit, modalButtonRef, message }) => {
  return (
    <Modal>
      <ModalHeader showCloseButon={false}>
        Mensaje
      </ModalHeader>
      <ModalBody>
        <div className="flex w-full">
          <div className="flex w-full text-center h-64 items-center justify-center">
            <div>
            <Spinner className="h-32 w-32 mb-8 text-kiosk-red" />
            {message}
            <button
              ref={modalButtonRef}
              type="button"
              className="h-1 w-1"
              onClick={() => onSubmit({ data: "success" })}
            />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default Loading;
