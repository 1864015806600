import * as React from 'react';
import { Redirect, Route } from "react-router-dom";
import { ROLES, RouteConstants } from 'constant';
import { getSession } from 'libs/AuthClient';
import { RouteType } from 'types/RouteItemType';
import { includesRoles } from 'libs';

const Login = React.lazy(() => import('components/Login'));
const NotFound = React.lazy(() => import('components/UI/NotFound'));
const UserList = React.lazy(() => import('components/User/UserList'));
const Dashboard = React.lazy(() => import('components/Dashboard'));
const ItemList = React.lazy(() => import('components/Item/ItemList'));
const WarehouseList = React.lazy(() => import('components/Warehouse/WarehouseList'));
const WarehouseItemsList = React.lazy(() => import('components/Warehouse/WarehouseItemsList'));
const ServiceList = React.lazy(() => import('components/Service/ServiceList'));
const ServiceItemList = React.lazy(() => import('components/Service/ServiceItemsList'));
const DeviceList = React.lazy(() => import('components/Device/DeviceList'));
const DeviceServiceList = React.lazy(() => import('components/Device/DeviceServiceList'));
const DeviceUserList = React.lazy(() => import('components/Device/DeviceUserList'));
const PlaceList = React.lazy(() => import('components/Place/PlaceList'));
const OrderReportList = React.lazy(() => import('components/Report/OrderReportList'));
const ManualReportList = React.lazy(() => import('components/Report/ManualReportList'));
const BillingReportList = React.lazy(() => import('components/Report/BillingReportList'));
const ComissionReportList = React.lazy(() => import('components/Report/ComissionReportList'));
const InvoiceReportList = React.lazy(() => import('components/Report/InvoiceReportList'));
const SettingList = React.lazy(() => import('components/Setting/SettingList'));
const ParamList = React.lazy(() => import('components/Setting/ParamList'));
const PlaceGroupList = React.lazy(() => import('components/PlaceGroup/PlaceGroupList'));
const PlaceGroupPlaceList = React.lazy(() => import('components/PlaceGroup/PlaceGroupPlaceList'));
const CronJobList = React.lazy(() => import('components/CronJob/CronJobList'));


/**
 * TODO: /
 * Improve how roles are set since right now this helps to filtrate initial 
 * routes however you still need to edit UIConstants.ts in order to show or hide
 * nested routes, maybe creating a definition dic so later roles can
 * be inherited.
 * 
 */
export const Routes: RouteType[] = [
  {
    component: Login,
    name: RouteConstants.LOGIN.name,
    path: RouteConstants.LOGIN.path,
    auth: false,
    exact: true
  },
  {
    component: Dashboard,
    name: RouteConstants.DASHBOARD.name,
    path: RouteConstants.DASHBOARD.path,
    roles: [ROLES.ADMIN, ROLES.SUPERVISOR],
    auth: true,
    exact: true
  },
  {
    component: ItemList,
    name: RouteConstants.ITEMS.name,
    path: RouteConstants.ITEMS.path,
    roles: [ROLES.SUPER_ADMIN],
    auth: true,
    exact: true
  },
  {
    component: WarehouseList,
    name: RouteConstants.WAREHOUSES.name,
    path: RouteConstants.WAREHOUSES.path,
    roles: [ROLES.ADMIN],
    auth: true,
    exact: true
  },
  {
    component: WarehouseItemsList,
    name: RouteConstants.WAREHOUSES_ITEMS.name,
    path: RouteConstants.WAREHOUSES_ITEMS.path,
    roles: [ROLES.ADMIN],
    auth: true,
    exact: true
  },
  {
    component: ServiceList,
    name: RouteConstants.SERVICES.name,
    path: RouteConstants.SERVICES.path,
    roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    auth: true,
    exact: true
  },
  {
    component: ServiceItemList,
    name: RouteConstants.SERVICES_ITEMS.name,
    path: RouteConstants.SERVICES_ITEMS.path,
    roles: [ROLES.SUPER_ADMIN],
    auth: true,
    exact: true
  },
  {
    component: DeviceList,
    name: RouteConstants.DEVICES.name,
    path: RouteConstants.DEVICES.path,
    roles: [ROLES.ADMIN],
    auth: true,
    exact: true
  },
  {
    component: DeviceServiceList,
    name: RouteConstants.DEVICES_SERVICES.name,
    path: RouteConstants.DEVICES_SERVICES.path,
    roles: [ROLES.ADMIN],
    auth: true,
    exact: true
  },
  {
    component: DeviceUserList,
    name: RouteConstants.DEVICES_USERS.name,
    path: RouteConstants.DEVICES_USERS.path,
    roles: [ROLES.ADMIN],
    auth: true,
    exact: true
  },
  {
    component: UserList,
    name: RouteConstants.USERS.name,
    path: RouteConstants.USERS.path,
    roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    auth: true,
    exact: true
  },
  {
    component: PlaceList,
    name: RouteConstants.PLACES.name,
    path: RouteConstants.PLACES.path,
    roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    auth: true,
    exact: true
  },
  {
    component: PlaceGroupList,
    name: RouteConstants.PLACE_GROUP.name,
    path: RouteConstants.PLACE_GROUP.path,
    roles: [ROLES.SUPER_ADMIN],
    auth: true,
    exact: true
  },
  {
    component: PlaceGroupPlaceList,
    name: RouteConstants.PLACE_GROUP_PLACES.name,
    path: RouteConstants.PLACE_GROUP_PLACES.path,
    roles: [ROLES.SUPER_ADMIN],
    auth: true,
    exact: true
  },
  {
    component: OrderReportList,
    name: RouteConstants.ORDERS.name,
    path: RouteConstants.ORDERS.path,
    roles: [ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.SUPER_ADMIN],
    auth: true,
    exact: true
  },
  {
    component: ManualReportList,
    name: RouteConstants.MANUAL_REPORT.name,
    path: RouteConstants.MANUAL_REPORT.path,
    roles: [ROLES.SUPER_ADMIN],
    auth: true,
    exact: true
  },
  {
    component: BillingReportList,
    name: RouteConstants.BILLING_REPORT.name,
    path: RouteConstants.BILLING_REPORT.path,
    roles: [ROLES.SUPER_ADMIN],
    auth: true,
    exact: true
  },
  {
    component: ComissionReportList,
    name: RouteConstants.COMISSION_REPORT.name,
    path: RouteConstants.COMISSION_REPORT.path,
    roles: [ROLES.SUPER_ADMIN],
    auth: true,
    exact: true
  },
  {
    component: InvoiceReportList,
    name: RouteConstants.INVOICE_REPORT.name,
    path: RouteConstants.INVOICE_REPORT.path,
    roles: [ROLES.SUPER_ADMIN],
    auth: true,
    exact: true
  },
  {
    component: SettingList,
    name: RouteConstants.SETTINGS.name,
    path: RouteConstants.SETTINGS.path,
    roles: [ROLES.SUPER_ADMIN],
    auth: true,
    exact: true
  },
  {
    component: ParamList,
    name: RouteConstants.PARAMETERS.name,
    path: RouteConstants.PARAMETERS.path,
    roles: [ROLES.SUPER_ADMIN],
    auth: true,
    exact: true
  },
  {
    component: CronJobList,
    name: RouteConstants.CRON.name,
    path: RouteConstants.CRON.path,
    roles: [ROLES.SUPER_ADMIN],
    auth: true,
    exact: true
  },
  {
    component: Login,
    name: '/',
    path: '/',
    auth: false,
    exact: true
  },
  {
    path: '*',
    name: '/',
    component: NotFound
  },
];


export const RenderRoutes = ({ loggedIn, roles }: { loggedIn: boolean, roles?: ROLES[] }) => {
  const session = getSession();
  loggedIn = (!!session.token || session.loginFetched || session.loggedIn || loggedIn);
  let filterdRoutes = Routes;
  filterdRoutes = filterdRoutes.map(route => {
    if (!loggedIn) {
      route.routes = route.routes?.filter(r => r.auth === false);
    }

    return route;
  });

  if (!loggedIn) {
    filterdRoutes = Routes.filter(route => loggedIn ? route.auth : !route.auth);
  }

  filterdRoutes = filterdRoutes.filter(r => {
    if (r.roles) {
      return includesRoles({ userRoles: roles, roles: r.roles })
    } else {
      return true;
    }
  });

  return filterdRoutes
    .map((route: any, index: number) => {
      return (
        <Route
          key={`${route.path}-${index}`}
          exact={route.exact}
          path={route.path}
          render={(props: any) => {
            const shouldLoginRedirect = !loggedIn && (window.location.pathname.indexOf('/login') > 0 || window.location.pathname !== '/login');
            const shouldDashboardRedirect = loggedIn && (window.location.pathname.indexOf('/login') >= 0 || window.location.pathname === '/');

            return shouldLoginRedirect ? (
              <Redirect
                to={{
                  pathname: RouteConstants.LOGIN.path
                }}
              />
            ) : shouldDashboardRedirect ? (
              <Redirect
                to={{
                  pathname: RouteConstants.DASHBOARD.path
                }}
              />
            ) : (
              <route.component routes={route.routes} routeName={route.name} {...props} />
            )

          }}
        />
      )
    });
}
