import * as React from 'react';
import AsyncSelect from 'react-select/async';

import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import { WarehouseItemType } from 'types/WarehouseType';
import { createItemToWarehouseService, updateItemFromWarehouseService } from 'services/WarehouseService';
import { ItemType } from 'types/ItemType';
import { getItemsQueryService } from 'services/ItemService';


export interface WarehouseItemManagementModalProps {
  warehouseUUID: string,
  warehouseItem?: WarehouseItemType
}

const WarehouseItemManagementModal: React.FC<WarehouseItemManagementModalProps & ModalProps> =
  ({ onDismiss, onSubmit, warehouseUUID, warehouseItem }) => {
    const submitButtonRef = React.useRef<HTMLInputElement>();
    const [selectedItem, setselectedItem] = React.useState<{ label: string, value: ItemType }>(
      warehouseItem ? { label: warehouseItem.item.name, value: warehouseItem.item } : null
    );
    const [state, setState] = React.useState<WarehouseItemType>({
      uuid: warehouseItem?.uuid || '',
      item: warehouseItem?.item || null,
      quantity: warehouseItem?.quantity || null,
      price: warehouseItem?.price || null,
    });

    const onInputChange = (e: React.SyntheticEvent) => {
      const data = e.currentTarget as HTMLInputElement;
      const { name, value } = data;

      setState({
        ...state,
        [name]: value
      });
    }

    const onSubmitHandler = async () => {
      let res;

      if (!warehouseItem) {
        res = await createItemToWarehouseService({
          itemUUID: selectedItem?.value?.uuid || state.item.uuid,
          warehouseUUID,
          quantity: parseInt(`${state.quantity}`, 10),
          price: parseFloat(`${state.price}`),
        });
      }else if (warehouseItem) {
        res = await updateItemFromWarehouseService({
          warehouseUUID,
          warehouseItemUUID: warehouseItem.uuid,
          itemUUID: selectedItem?.value?.uuid || state.item.uuid,
          quantity: parseInt(`${state.quantity}`, 10),
          price: parseFloat(`${state.price}`),
        });
      }

      onSubmit(res);
    }

    const loadItems = (inputValue: string) => {
      return getItemsQueryService({ query: inputValue })
        .then((items: ItemType[]) => {
          return items.map(item => ({
            label: item.name,
            value: item
          }))
        })
    };

    return (
      <Modal onDismiss={onDismiss} >
        <ModalHeader>
          {warehouseItem ? "Editar producto en almacen" : 'Nuevo producto de almacen'}
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col w-full">
            <div className="flex flex-col w-full">
              <form className="space-y-6" onSubmit={async e => {
                e.preventDefault();
                await onSubmitHandler();
              }}>
                <div className="relative">
                  <label htmlFor="item" className="sr-only">Productos</label>
                  <AsyncSelect
                    id="item"
                    name="item"
                    placeholder="Producto"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={selectedItem}
                    loadOptions={loadItems}
                    onChange={item => setselectedItem(item as any)}
                  />
                  {!selectedItem && (
                    <input
                      required
                      name="item"
                      className="outline-none focus:outline-none absolute"
                      style={{ height: 1, opacity: 0 }}
                    />
                  )}
                </div>

                <div>
                  <label htmlFor="quantity" className="sr-only">Cantidad</label>
                  <input
                    required
                    id="quantity"
                    name="quantity"
                    type="number"
                    value={state.quantity || ''}
                    className="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Cantidad"
                    onChange={onInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="price" className="sr-only">Precio</label>
                  <input
                    required
                    id="price"
                    name="price"
                    type="number"
                    value={state.price || ''}
                    className="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Precio"
                    onChange={onInputChange}
                  />
                </div>

                <div>
                  <button ref={submitButtonRef} type="submit" className="hidden" />
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => submitButtonRef.current.click()}
          >
            Aceptar
          </button>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={onDismiss}
          >
            Cancelar
          </button>
        </ModalFooter>
      </Modal>
    );
  }

export default WarehouseItemManagementModal;
