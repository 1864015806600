import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";

// Components declaration.
import AuthReducer from "./AuthReducer";

export type RootState = ReturnType<typeof rootReducer>;

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;

const enhancer = composeEnhancers(
    applyMiddleware(thunkMiddleware),
);

// Reducers declaration.
const rootReducer = combineReducers({
    AuthReducer
});

// Store declaration
export const store = createStore(
    rootReducer,
    enhancer
);


export default rootReducer;