import * as React from "react";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

import { useModal } from "components/UI/Modal/ModalProvider";
import Table from "components/UI/Table";
import PlaceManagementModal from "components/Place/PlaceManagementModal";
import { placesService } from "services/PlaceService";
import { PlaceResponseType, PlaceType } from "types/PlaceType";

interface PlaceListProps {}

const PlaceList: React.FC<PlaceListProps> = () => {
  const [limit, setLimit] = React.useState<number>(25);
  const placeManagementModal = useModal(PlaceManagementModal);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewPlaceHanlder = async () => {
    const res = await placeManagementModal();

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onEditPlaceHandler = async (place: PlaceType) => {
    const res = await placeManagementModal({ place });

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de sucursales"
        limit={limit}
        columns={[
          { title: "Sucursal", field: "name" },
          { title: "Código", field: "code" },
          {
            title: "Activo",
            field: "active",
            render: (rowData: PlaceType) => (
              <span>{rowData.active ? "Si" : "No"}</span>
            ),
          },
        ]}
        data={async (query) => {
          const res = (await placesService({
            input: { page: query.page + 1, query: query.search },
          })) as PlaceResponseType;

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "edit",
            tooltip: "Editar sucursal",
            onClick: (_, rowData: PlaceType) => onEditPlaceHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Agregar sucursal",
            isFreeAction: true,
            onClick: onNewPlaceHanlder,
          },
        ]}
      />
    </div>
  );
};

export default PlaceList;
