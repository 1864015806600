import { gql } from "@apollo/client";
import { authClient } from "libs/AuthClient";
import { CityType } from "types/CityType";

const getCitiesQueryService = ({ query }: { query: string }) => {
  return authClient()
    .query({
      query: gql`
        query citiesQuery($query: String!) {
          citiesQuery(query: $query) {
            uuid
            name
          }
        }
      `,
      variables: {
        query,
      },
    })
    .then(({ data }): Object => {
      const itemsData = data.citiesQuery as CityType[];

      return itemsData;
    });
};

export { getCitiesQueryService };
