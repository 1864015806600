import { gql, useLazyQuery } from "@apollo/client";
import * as React from "react";
import ReactDatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const QUERY = gql`
  query weeklyIncome($date: Date!) {
    weeklyIncome(date: $date) {
      dayOfWeek
      income
    }
  }
`;

const getIntroOfPage = (label: string) => {
  if (label === "Page A") {
    return "Page A is about men's clothing";
  }
  if (label === "Page B") {
    return "Page B is about women's dress";
  }
  if (label === "Page C") {
    return "Page C is about women's bag";
  }
  if (label === "Page D") {
    return "Page D is about household goods";
  }
  if (label === "Page E") {
    return "Page E is about food";
  }
  if (label === "Page F") {
    return "Page F is about baby food";
  }
  return "";
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-gray-400 p-4 text-gray-200">
        <p className="label">{`Día ${label}`}</p>
        <p className="label">
          <NumberFormat
            value={payload[0].value}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </p>
      </div>
    );
  }

  return null;
};

interface BarChartProps {
  title?: string;
}

const BarGraph: React.FC<BarChartProps> = ({ title }) => {
  const [date, setDate] = React.useState<Date>(new Date());
  const [getData, { data, loading }] = useLazyQuery(QUERY);

  const fetchData = React.useCallback(() => {
    getData({ variables: { date } });
  }, [date]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="w-full h-full flex flex-col space-y-4">
      <div className="flex flex-row bg-gray-300 p-2 mx-4">
        <h1 className="w-1/2 text-2xl">{title}</h1>
        <div className="w-1/2 flex flex-row items-center space-x-2">
          <span className="text-sm w-1/2">Semana del: </span>
          <ReactDatePicker
            className="w-full text-base px-4 text-center rounded focus:outline-none focus:ring"
            selected={date}
            onChange={(d: Date) => setDate(d)}
          />
        </div>
      </div>
      {loading ? (
        "loading..."
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data?.weeklyIncome}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="dayOfWeek" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend content={() => null} />
            <Bar dataKey="income" barSize={20} fill="#1f2937bd" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default BarGraph;
