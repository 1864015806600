import * as React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import ReactDatePicker from "react-datepicker";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";

const QUERY = gql`
  query serviceIncome($date: Date!) {
    serviceIncome(date: $date) {
      service
      income
      orders
    }
  }
`;

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  const labelFontSize =
    payload.service.length > 10 ? payload.service.length - (payload.service.length / 2) : 16;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={0}
        textAnchor="middle"
        fontSize={labelFontSize}
        fill={fill}
      >
        {`${payload.service.length >= 20 ? `${payload.service.substring(0, 20)}...` : payload.service}`}
      </text>
      <text x={cx} y={cy} dy={18} textAnchor="middle" fontSize={8} fill={fill}>
        {`($${payload.income.toFixed(2)})`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {`${payload.orders} ${payload.orders > 1 ? "ordenes" : "orden"}`}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
        fontSize={10}
      >
        {`(${(percent * 100).toFixed(2)}% de ventas)`}
      </text>
    </g>
  );
};

interface PieGraphProps {
  title?: string;
}

const PieGraph: React.FC<PieGraphProps> = ({ title }) => {
  const [date, setDate] = React.useState<Date>(new Date());
  const [getData, { data, loading }] = useLazyQuery(QUERY);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);

  const fetchData = React.useCallback(() => {
    getData({ variables: { date } });
  }, [date]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onPieEnter = (_: any, index: number) => setActiveIndex(index);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex flex-row bg-gray-300 p-2 mx-4">
        <h1 className="w-2/3 text-2xl">{title}</h1>
        <div className="w-1/3 flex flex-row items-center space-x-2">
          <span>Dia: </span>
          <ReactDatePicker
            className="w-full text-base px-4 text-center rounded focus:outline-none focus:ring"
            selected={date}
            onChange={(d: Date) => setDate(d)}
          />
        </div>
      </div>
      {loading ? (
        "loading..."
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data?.serviceIncome}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#1f2937bd"
              dataKey="income"
              onMouseEnter={onPieEnter}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default PieGraph;
