import * as React from "react";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

import { useModal } from "components/UI/Modal/ModalProvider";
import Table from "components/UI/Table";
import ItemManagementModal from "./ItemManagementModal";
import { getItemsService, removeItemService } from "services/ItemService";
import { ItemResponseType, ItemType } from "types/ItemType";
import MessageModal from "components/UI/Message/MessageModal";

interface ItemListProps {}

const ItemList: React.FC<ItemListProps> = () => {
  const messageModal = useModal(MessageModal);
  const [limit, setLimit] = React.useState<number>(25);
  const itemManagementModal = useModal(ItemManagementModal);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewItemHanlder = async () => {
    const res = await itemManagementModal();

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onEditItemHandler = async (item: ItemType) => {
    const res = await itemManagementModal({ item });

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onRemoveItemHandler = async (item: ItemType) => {
    const res = await messageModal({
      message: `Remover producto ${item.name}?`,
      confirm: true,
    });
    if (res?.data) {
      const res2: any = await removeItemService({
        uuid: item.uuid,
      });

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de catalogo de productos"
        limit={limit}
        columns={[
          { title: "Producto", field: "name" },
          { title: "Sku", field: "sku" },
          { title: "Tipo", field: "type" },
          {
            title: "Disponible",
            field: "available",
            render: (rowData: ItemType) => (
              <span>{rowData.available ? "Si" : "No"}</span>
            ),
          },
        ]}
        data={async (query) => {
          const res = (await getItemsService({
            input: { page: query.page + 1, query: query.search },
          })) as ItemResponseType;

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "edit",
            tooltip: "Editar Producto",
            onClick: (_, rowData: ItemType) => onEditItemHandler(rowData),
          }),
          (_) => ({
            icon: "delete",
            tooltip: "Eliminar Producto",
            onClick: (_, rowData: ItemType) => onRemoveItemHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Agregar Item",
            isFreeAction: true,
            onClick: onNewItemHanlder,
          },
        ]}
      />
    </div>
  );
};

export default ItemList;
