import { AUTH_ACTION } from "constant";
import { getSession, removeSession } from "libs/AuthClient";
import { AuthReducerActionType, AuthType } from "types/AuthType";


const getInitialState = () => {
	const { token, loginFetched } = getSession();

	return {
		loggedIn: false,
    loginFetched,
		token
	} as AuthType
};


const AuthReducer = (state: AuthType = getInitialState(), action: AuthReducerActionType) => {
	switch (action.type) {
		case AUTH_ACTION.LOGIN_SUCCESS:
			const newState: AuthType = {
				...state,
				...action,
				loggedIn: true,
			}

			return newState;
		case AUTH_ACTION.LOGOUT:
			removeSession();

			return {
				loggedIn: false
			};
		default:
			return state;
	}
}

export default AuthReducer;