import * as React from 'react';

export interface Step {
  title: string
  percentage: number,
  icon?: JSX.Element
}

interface StepNavigatorProps {
  steps: Step[],
  className?: string
}

const StepNavigator: React.FC<StepNavigatorProps> = ({ steps, className }) => {
  return (
    <div className={className}>
      <div className="flex">
        <div className={`w-full grid grid-rows-${steps.length} grid-flow-col`}>
          {steps.map((step, index) => {
            return (
              <div key={`nav-${index}`}>
                <div className="relative mb-2">
                  {index !== 0 && (
                    <div className="absolute flex align-center items-center align-middle content-center top-5" style={{ width: 'calc(100% - 2.5rem - 1rem)', transform: 'translate(-50%, -50%)' }}>
                      <div className={`w-full ${step.percentage >= 100 ? 'bg-gray-900' : 'bg-gray-200'} rounded items-center align-middle align-center flex-1`}>
                        <div className="w-0 bg-gray-600 py-1 rounded" style={{ width: `${step.percentage}%` }}></div>
                      </div>
                    </div>
                  )}

                  <div className={`w-10 h-10 mx-auto ${step.percentage >= 100 ? 'bg-gray-900 border-gray-800' : 'bg-white border-gray-200'} border-2 rounded-full text-lg text-white flex items-center`}>
                    <span className={`flex justify-center items-center text-center ${step.percentage >= 100 ? 'text-white' : 'text-gray-600'} w-full`}>
                      {step.icon || (
                        <svg
                          version="1.1"
                          className="w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 17.837 17.837"
                          xmlSpace="preserve"
                        >
                          <g>
                            <path style={{ fill: 'white' }} d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27
                            c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0
                            L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"/>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                        </svg>
                      )}
                    </span>
                  </div>
                </div>

                <div className="text-xs text-center text-gray-500">{step.title}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default StepNavigator;