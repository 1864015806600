import { gql } from "@apollo/client";
import { deepCopy } from "libs";
import { authClient } from "libs/AuthClient";
import { PaginationParams } from "types/PaginationType";
import { PlaceGroupCreate, PlaceGroupUpdate } from "types/PlaceGroup";

const createPlaceGroupService = (placeGroup: PlaceGroupCreate) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation createGroupPlace($input: CreatePlaceGroupInput!) {
          createPlaceGroup(input: $input)
        }
      `,
      variables: {
        input: {
          ...placeGroup,
        },
      },
    })
    .then(({ data }): Object => {
      const placeGroupData = data.createPlaceGroup;

      return {
        data: placeGroupData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const updatePlaceGroupService = (placeGroup: PlaceGroupUpdate) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation updateGroupPlace($input: UpdatePlaceGroupInput!) {
          updatePlaceGroup(input: $input)
        }
      `,
      variables: {
        input: {
          ...placeGroup,
        },
      },
    })
    .then(({ data }): Object => {
      const placeGroupData = data.updatePlaceGroup;

      return {
        data: placeGroupData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const placeGroupService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query placeGroups($input: BasePaginationInput!) {
          placeGroups(input: $input) {
            limit
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            docs {
              ... on PlaceGroup {
                uuid
                name
                code
                active
              }
            }
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const placeGroupsData = data.placeGroups;

      return {
        ...placeGroupsData,
      };
    });
};

const placeGroupPlacesService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query placeGroupPlaces($input: BasePaginationInput!) {
          placeGroupPlaces(input: $input) {
            limit
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            docs {
              ... on Place {
                uuid
                name
              }
            }
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const placeGroupsData = data.placeGroupPlaces;

      return {
        ...placeGroupsData,
      };
    });
};

const placeGroupQueryService = ({ query }: { query: string }) => {
  return authClient()
    .query({
      query: gql`
        query placeGroupQuery($query: String!) {
          placeGroupQuery(query: $query) {
            uuid
            name
          }
        }
      `,
      variables: {
        query,
      },
    })
    .then(({ data }): Object => {
      const placeGroupData = data.placeGroupQuery;

      return placeGroupData;
    });
};

export {
  placeGroupService,
  placeGroupPlacesService,
  createPlaceGroupService,
  updatePlaceGroupService,
  placeGroupQueryService,
};
