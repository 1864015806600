import { SideBarItem } from "components/UI";
import { Routes } from "routing";
import { ROLES } from "./AuthConstants";
import { RouteConstants } from "./RoutesConstants";


export const MenuList: SideBarItem[] = [
  {
    key: RouteConstants.DASHBOARD.key,
    label: RouteConstants.DASHBOARD.name,
    path: RouteConstants.DASHBOARD.path,
    roles: [ROLES.ADMIN, ROLES.SUPERVISOR],
    icon: 'home'
  },
  {
    key: RouteConstants.ADMINISTRATION.key,
    label: RouteConstants.ADMINISTRATION.name,
    path: RouteConstants.ADMINISTRATION.path,
    roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    icon: 'adjusments',
    children: [
      {
        key: RouteConstants.USERS.key,
        label: RouteConstants.USERS.name,
        path: RouteConstants.USERS.path,
        roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
        icon: 'userGroup'
      },
      {
        key: RouteConstants.ITEMS.key,
        label: RouteConstants.ITEMS.name,
        path: RouteConstants.ITEMS.path,
        roles: [ROLES.SUPER_ADMIN],
        icon: 'inventory'
      },
      {
        key: RouteConstants.WAREHOUSES.key,
        label: RouteConstants.WAREHOUSES.name,
        path: RouteConstants.WAREHOUSES.path,
        roles: [ROLES.ADMIN],
        icon: 'warehouses'
      },
      {
        key: RouteConstants.SERVICES.key,
        label: RouteConstants.SERVICES.name,
        path: RouteConstants.SERVICES.path,
        roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
        icon: 'services'
      },
      {
        key: RouteConstants.PLACES.key,
        label: RouteConstants.PLACES.name,
        path: RouteConstants.PLACES.path,
        roles: [ROLES.SUPER_ADMIN],
        icon: 'locationMark'
      },
      {
        key: RouteConstants.PLACE_GROUP.key,
        label: RouteConstants.PLACE_GROUP.name,
        path: RouteConstants.PLACE_GROUP.path,
        roles: [ROLES.SUPER_ADMIN],
        icon: 'gridAdd'
      },
      {
        key: RouteConstants.DEVICES.key,
        label: RouteConstants.DEVICES.name,
        path: RouteConstants.DEVICES.path,
        roles: [ROLES.ADMIN],
        icon: 'desktopComputar'
      },
    ]
  },
  {
    key: RouteConstants.REPORTS.key,
    label: RouteConstants.REPORTS.name,
    path: RouteConstants.REPORTS.path,
    icon: 'documentReport',
    roles: [ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.SUPER_ADMIN],
    children: [
      {
        key: RouteConstants.ORDERS.key,
        label: RouteConstants.ORDERS.name,
        path: RouteConstants.ORDERS.path,
        roles: [ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.SUPER_ADMIN],
        icon: 'cash'
      },
      {
        key: RouteConstants.MANUAL_REPORT.key,
        label: RouteConstants.MANUAL_REPORT.name,
        path: RouteConstants.MANUAL_REPORT.path,
        roles: [ROLES.SUPER_ADMIN],
        icon: 'manual'
      },
      {
        key: RouteConstants.BILLING_REPORT.key,
        label: RouteConstants.BILLING_REPORT.name,
        path: RouteConstants.BILLING_REPORT.path,
        roles: [ROLES.SUPER_ADMIN],
        icon: 'billing'
      },
      {
        key: RouteConstants.INVOICE_REPORT.key,
        label: RouteConstants.INVOICE_REPORT.name,
        path: RouteConstants.INVOICE_REPORT.path,
        roles: [ROLES.SUPER_ADMIN],
        icon: 'invoice'
      },
      // {
      //   key: RouteConstants.COMISSION_REPORT.key,
      //   label: RouteConstants.COMISSION_REPORT.name,
      //   path: RouteConstants.COMISSION_REPORT.path,
      //   roles: [ROLES.SUPER_ADMIN],
      //   icon: 'invoice'
      // },
    ]
  },
  {
    key: RouteConstants.SETTINGS.key,
    label: RouteConstants.SETTINGS.name,
    path: RouteConstants.SETTINGS.path,
    roles: [ROLES.SUPER_ADMIN],
    icon: 'cog'
  },
  {
    key: RouteConstants.CRON.key,
    label: RouteConstants.CRON.name,
    path: RouteConstants.CRON.path,
    roles: [ROLES.SUPER_ADMIN],
    icon: 'cron'
  }
];