import * as React from 'react';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';

import Select, { OptionsType } from 'react-select';
import { includesRoles } from 'libs';
import { ROLES } from 'constant';
import { AuthSelector } from 'selectors/AuthSelector';
import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import { UserType } from 'types/UserType';
import { createUserService, getUserRolesPermissionsService, updateUserService } from 'services/UserService';
import { getRolesPermissionsService } from 'services/AuthService';
import { RolesPermissions } from 'types/AuthType';
import { placesQueryService } from 'services/PlaceService';
import { PlaceType } from 'types/PlaceType';


export interface UserManagementModalProps {
  user?: UserType & {places?: PlaceType[]}
}

const UserManagementModal: React.FC<UserManagementModalProps & ModalProps> = ({ onDismiss, onSubmit, user }) => {
  const submitButtonRef = React.useRef<HTMLInputElement>();
  const auth = useSelector(AuthSelector);
  const [roles, setRoles] = React.useState<OptionsType<{ label: string; value: string; }>>([]);
  const [permissions, setPermissions] = React.useState<OptionsType<{ label: string; value: string; }>>([]);
  const [selectedPlaces, setSelectedPlaces] = React.useState<{ label: string; value: PlaceType }[]>([]);
  const [selectedRoles, setSelectedRoles] = React.useState<OptionsType<{ label: string; value: string; }>>([]);
  const [selectedPermissions, setSelectedPermissions] = React.useState<OptionsType<{ label: string; value: string; }>>([]);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [state, setState] = React.useState<UserType & { placeCodes: string[] }>({
    username: '',
    password: '',
    placeCodes: [],
    active: user?.active
  });
  const isSuperAdmin = includesRoles({ userRoles: auth.roles, roles: [ROLES.SUPER_ADMIN] });
  const isAdmin = includesRoles({ userRoles: auth.roles, roles: [ROLES.ADMIN] });

  React.useEffect(() => {
    getRolesPermissionsService()
      .then((res: RolesPermissions) => {
        const optionRoles = res.roles.map(r => ({ label: r, value: r }));
        const optionPermissions = res.permissions.map(r => ({ label: r, value: r }));

        setRoles(optionRoles);;
        setPermissions(optionPermissions);
      });

    if (user) {
      getUserRolesPermissionsService({ uuid: user.uuid })
        .then((res: RolesPermissions) => {

          setSelectedRoles(res.roles?.map(r => ({ label: r, value: r })))
          setSelectedPermissions(res.permissions?.map(p => ({ label: p, value: p })))
        });
    }

    setSelectedPlaces(user?.places.map(p => ({ label: p.name, value: p })));
  }, [user]);

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;


    setState({
      ...state,
      [name]: value
    });
  }

  const onSubmitHandler = async () => {
    setSubmitting(true);
    let res;

    if (!user) {
      res = await createUserService({
        ...state,
        roles: selectedRoles.map(r => r.value),
        permissions: selectedPermissions.map(p => p.value),
        placeCodes: selectedPlaces?.map(place => place.value.code)
      });
    } else {
      res = await updateUserService({
        uuid: user.uuid,
        password: state.password,
        roles: selectedRoles.map(sr => sr.value),
        permissions: selectedPermissions.map(sp => sp.value),
        active: !!(state.active),
        placeCodes: selectedPlaces?.map(place => place.value.code)
      });
    }

    onSubmit(res);
  }

  const loadPlaces = (inputValue: string) => {
    return placesQueryService({ query: inputValue })
      .then((places: PlaceType[]) => {
        return places.map(place => ({
          label: place.name,
          value: place
        }))
      })
  };

  return (
    <Modal onDismiss={onDismiss} >
      <ModalHeader>
        {user ? "Editar Roles" : 'Nuevo Usuario'}
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full h-96">
            <form className="space-y-6" onSubmit={async e => {
              e.preventDefault();
              await onSubmitHandler();
            }}>
              {(!user || isSuperAdmin || isAdmin) && (
                <div className="rounded-md shadow-sm -space-y-px">
                  {!user && (
                    <div>
                      <label htmlFor="username" className="sr-only">Nombre de usuario</label>
                      <input
                        required={!user && !isSuperAdmin}
                        id="username"
                        name="username"
                        type="text"
                        value={state.username}
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                        placeholder="Nombre de usuario"
                        onChange={onInputChange}
                      />
                    </div>
                  )}
                  <div>
                    <label htmlFor="password" className="sr-only">Contraseña</label>
                    <input
                      required={!user}
                      id="password"
                      name="password"
                      type={submitting ? 'text' : 'password'}
                      value={submitting ? state.password.replaceAll(/./g, '*') : state.password}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Contraseña"
                      onChange={onInputChange}
                      autoComplete="0"
                    />
                  </div>
                </div>
              )}

              {isSuperAdmin && (
                <div className="relative">
                  <label htmlFor="place" className="sr-only">Sucursal a assignar</label>
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    id="place"
                    name="place"
                    placeholder="Sucursal a assignar"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={selectedPlaces}
                    value={selectedPlaces}
                    loadOptions={loadPlaces}
                    onChange={places => setSelectedPlaces(places as any)}
                  />
                  {selectedPlaces?.length === 0 && (
                    <input
                      required
                      name="place"
                      className="outline-none focus:outline-none absolute"
                      style={{ height: 1, opacity: 0 }}
                    />
                  )}
                </div>
              )}

              <div>
                <label htmlFor="roles" className="sr-only">Roles</label>
                <Select
                  id="roles"
                  defaultValue={[]}
                  value={selectedRoles}
                  isMulti
                  name="roles"
                  options={roles}
                  placeholder="Asignar roles"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={values => setSelectedRoles(values)}
                />
              </div>

              <div>
                <label htmlFor="permissions" className="sr-only">Permisos</label>
                <Select
                  id="permissions"
                  defaultValue={[]}
                  value={selectedPermissions}
                  isMulti
                  name="permissions"
                  placeholder="Asignar permisos"
                  options={permissions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={values => setSelectedPermissions(values)}
                />
              </div>

              <div className="mb-28">
                {includesRoles({ userRoles: auth.roles, roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN] }) && (
                  <label className="flex items-center space-x-3 mt-2">
                    <input
                      type="checkbox"
                      name="active"
                      defaultChecked={state.active}
                      className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                      onClick={() => setState({ ...state, active: !state.active })}
                    />
                    <span className="text-gray-500 font-medium">Activo</span>
                  </label>
                )}
              </div>

              <div>
                <button ref={submitButtonRef} type="submit" className="hidden" />
              </div>

            </form>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => submitButtonRef.current.click()}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onDismiss}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default UserManagementModal;
