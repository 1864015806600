import * as React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

import { useModal } from "components/UI/Modal/ModalProvider";
import Table from "components/UI/Table";
import PlaceGroupManagementModal from "components/PlaceGroup/PlaceGroupManagementModal";
import { PlaceGroup, PlaceGroupResponse } from "types/PlaceGroup";
import { placeGroupService } from "services/PlaceGroupService";
import { RouteConstants } from "constant";

interface PlaceGroupListListProps {}

const PlaceGroupList: React.FC<PlaceGroupListListProps> = () => {
  const history = useHistory();
  const [limit, setLimit] = React.useState<number>(25);
  const placeGroupManagementModal = useModal(PlaceGroupManagementModal);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewPlaceHanlder = async () => {
    const res = await placeGroupManagementModal();

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onPlaceGroupAdminHandler = async (placeGroup: PlaceGroup) => {
    history.push(
      RouteConstants.PLACE_GROUP_PLACES.path.replace(
        ":placeGroupUUID",
        placeGroup.uuid
      )
    );
  };

  const onEditPlaceHandler = async (placeGroup: PlaceGroup) => {
    const res = await placeGroupManagementModal({ placeGroup });

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de groupo de sucursales"
        limit={limit}
        columns={[
          { title: "Nombre", field: "name" },
          { title: "Codigo", field: "code" },
          {
            title: "Activo",
            field: "active",
            render: (rowData: PlaceGroup) => (
              <span>{rowData.active ? "Si" : "No"}</span>
            ),
          },
        ]}
        data={async (query) => {
          const res = (await placeGroupService({
            input: { page: query.page + 1, query: query.search },
          })) as PlaceGroupResponse;

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "edit",
            tooltip: "Editar Grupo",
            onClick: (_, rowData: PlaceGroup) => onEditPlaceHandler(rowData),
          }),
          {
            icon: "inventory",
            tooltip: "Administrar sucursales",
            onClick: (_: any, rowData: PlaceGroup) =>
              onPlaceGroupAdminHandler(rowData),
          },
          {
            icon: "add",
            tooltip: "Agregar Grupo",
            isFreeAction: true,
            onClick: onNewPlaceHanlder,
          },
        ]}
      />
    </div>
  );
};

export default PlaceGroupList;
