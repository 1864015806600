import * as React from 'react';
import AsyncSelect from 'react-select/async';

import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import {  addUserToDeviceService } from 'services/DeviceService';
import { UserType } from 'types/UserType';
import { getUsersPlaceQueryService } from 'services/UserService';


export interface ServiceUserManagementModalProps {
  deviceUUID: string,
}

const ServiceUserManagementModal: React.FC<ServiceUserManagementModalProps & ModalProps> =
  ({ onDismiss, onSubmit, deviceUUID }) => {
    const submitButtonRef = React.useRef<HTMLInputElement>();
    const [selectedUser, setSelectedUser] = React.useState<{ label: string, value: UserType }>(null);

    const onSubmitHandler = async () => {
      const res = await addUserToDeviceService({
          deviceUUID,
          userUUID: selectedUser.value.uuid,
        });

      onSubmit(res);
    }

    const loadUsers = (inputValue: string) => {
      return getUsersPlaceQueryService({ query: inputValue })
        .then((users: UserType[]) => {
          return users.map(user => ({
            label: user.username,
            value: user
          }))
        })
    };

    return (
      <Modal onDismiss={onDismiss} >
        <ModalHeader>
          Nuevo usuario en dispositivo
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col w-full">
            <div className="flex flex-col w-full">
              <form className="space-y-6" onSubmit={async e => {
                e.preventDefault();
                await onSubmitHandler();
              }}>
                <div className="relative">
                  <label htmlFor="item" className="sr-only">Usuarios</label>
                  <AsyncSelect
                    id="item"
                    name="item"
                    placeholder="Usuario"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={selectedUser}
                    loadOptions={loadUsers}
                    onChange={item => setSelectedUser(item as any)}
                  />
                  {!selectedUser && (
                    <input
                      required
                      name="item"
                      className="outline-none focus:outline-none absolute"
                      style={{ height: 1, opacity: 0 }}
                    />
                  )}
                </div>

                <div>
                  <button ref={submitButtonRef} type="submit" className="hidden" />
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => submitButtonRef.current.click()}
          >
            Aceptar
          </button>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={onDismiss}
          >
            Cancelar
          </button>
        </ModalFooter>
      </Modal>
    );
  }

export default ServiceUserManagementModal;
