import * as React from "react";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

import { useModal } from "components/UI/Modal/ModalProvider";
import Table from "components/UI/Table";
import SettingManagementModal from "./SettingManagementModal";
import MessageModal from "components/UI/Message/MessageModal";
import { removeSettingService, settingsService } from "services/SettingService";
import { SettingResponseType, SettingType } from "types/SettingType";
import { useHistory } from "react-router-dom";
import { RouteConstants } from "constant";

interface SettingListProps {}

const SettingList: React.FC<SettingListProps> = () => {
  const history = useHistory();
  const messageModal = useModal(MessageModal);
  const [limit, setLimit] = React.useState<number>(25);
  const settingManagementModal = useModal(SettingManagementModal);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewSettingHanlder = async () => {
    const res = await settingManagementModal();

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onEditSettingHandler = async (setting: SettingType) => {
    const res = await settingManagementModal({ setting });

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onServiceItemsHandler = async (setting: SettingType) => {
    history.push(
      RouteConstants.PARAMETERS.path.replace(":settingUUID", setting.uuid)
    );
  };

  const onRemoveSettingHandler = async (setting: SettingType) => {
    const res = await messageModal({
      message: `Remover setting ${setting.name}?`,
      confirm: true,
    });
    if (res?.data) {
      const res2: any = await removeSettingService({
        uuid: setting.uuid,
      });

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de configuraciones"
        limit={limit}
        columns={[
          { title: "Nombre", field: "name" },
          { title: "Código", field: "code" },
        ]}
        data={async (query) => {
          const res = (await settingsService({
            input: { page: query.page + 1, query: query.search },
          })) as SettingResponseType;

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "edit",
            tooltip: "Editar configuración",
            onClick: (_, rowData: SettingType) => onEditSettingHandler(rowData),
          }),
          (_) => ({
            icon: "delete",
            tooltip: "Eliminar configuración",
            onClick: (_, rowData: SettingType) =>
              onRemoveSettingHandler(rowData),
          }),
          (_) => ({
            icon: "list",
            tooltip: "Administrar parametros",
            onClick: (_, rowData: SettingType) =>
              onServiceItemsHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Agregar configuración",
            isFreeAction: true,
            onClick: onNewSettingHanlder,
          },
        ]}
      />
    </div>
  );
};

export default SettingList;
