import * as React from "react";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { resolveFileUploads } from "libs";
import Table from "components/UI/Table";
import {
  manualReportsService,
  regenerateReportService,
  removeReportService,
  sendReportService,
} from "services/ReportService";
import { Report, ReportResponseType } from "types/Report";
import { useModal } from "components/UI/Modal/ModalProvider";
import MessageModal from "components/UI/Message/MessageModal";
import Loading from "components/UI/Modal/Loading";
import ManualReportModal from "./ManualReportModal";
import ReportUpdateModal from "./ReportUpdateModal";
import NumberFormat from "react-number-format";

interface ManualReportListProps {}

const ManualReportList: React.FC<ManualReportListProps> = () => {
  const history = useHistory();
  const modalButtonRef = React.useRef<HTMLButtonElement>(null);
  const loadingModal = useModal(Loading);
  const messageModal = useModal(MessageModal);
  const reportUpdateModal = useModal(ReportUpdateModal);
  const generateReportModal = useModal(ManualReportModal);
  const [limit, setLimit] = React.useState<number>(25);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onGenerateReportHandler = async () => {
    const res = await generateReportModal();

    if (res?.data) {
      toast.success(res.data.message);
      history.push(res.data.url);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onRemoveReportHandler = async (report: Report) => {
    const res = await messageModal({
      message: `Remover reporte ${report.service.name}?`,
      confirm: true,
    });
    if (res?.data) {
      const res2: any = await removeReportService({ uuid: report.uuid });

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  const openReport = (report: Report) => {
    window.open(resolveFileUploads(report.documentUrl), "_blank");
  };

  const regeneratePDFHandler = async (report: Report) => {
    const res = await messageModal({
      message: `Regenerar reporte ${report.service.name}?`,
      confirm: true,
    });
    if (res?.data) {
      loadingModal({ modalButtonRef, message: "Regenerando reporte" });
      const res2: any = await regenerateReportService({ uuid: report.uuid });

      modalButtonRef.current.click();

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  const sendReportHandler = async (report: Report) => {
    const res = await messageModal({
      message: `Enviar reporte ${report.service.name}?`,
      confirm: true,
    });
    if (res?.data) {
      loadingModal({ modalButtonRef, message: "Enviando reporte" });
      const res2: any = await sendReportService({ uuid: report.uuid });

      modalButtonRef.current.click();

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  const onReportUpdateHanlder = async (report: Report) => {
    const result = await reportUpdateModal({ report });

    if (result?.data) {
      toast.success(result.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de reporte manuales"
        limit={limit}
        columns={[
          { title: "Servicio", field: "service.name" },
          {
            title: "Monto",
            render: (rowData: Report) => (
              <NumberFormat
                value={rowData.amount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            ),
          },
          {
            title: "Fecha de reporte",
            render: (rowData: Report) => (
              <div className="flex flex-row space-x-2">
                <span>
                  {DateTime.fromISO(rowData.fromDate).toFormat("dd/MM/yyyy")}
                </span>
                <span>-</span>
                <span>
                  {DateTime.fromISO(rowData.toDate).toFormat("dd/MM/yyyy")}
                </span>
              </div>
            ),
          },
          {
            title: "Creación",
            render: (rowData: Report) => (
              <span>
                {DateTime.fromISO(rowData.createdAt).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}
              </span>
            ),
          },
          {
            title: "Deposito",
            render: (rowData: Report) => (
              <span>
                {rowData.documentUrlExtra || rowData.paid ? "SI" : "NO"}
              </span>
            ),
          },
          {
            title: "Enviado",
            render: (rowData: Report) => (
              <span>{rowData.sent ? "SI" : "NO"}</span>
            ),
          },
        ]}
        data={async (query) => {
          const res = (await manualReportsService({
            input: { page: query.page + 1, query: query.search },
          })) as ReportResponseType;

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "pageview",
            tooltip: "Previsualizar Reporte",
            onClick: (_, rowData: Report) => openReport(rowData),
          }),
          (_) => ({
            icon: "article",
            tooltip: "Regenerar TXT",
            onClick: (_, rowData: Report) => regeneratePDFHandler(rowData),
          }),
          (_) => ({
            icon: "confirmation_number",
            tooltip: "Registrar Pago",
            onClick: (_, rowData: Report) => onReportUpdateHanlder(rowData),
          }),
          (data: Report) =>
            data.paid
              ? {
                  icon: "send",
                  tooltip: "Enviar Reporte",
                  onClick: (_, rowData: Report) => sendReportHandler(rowData),
                }
              : null,
          (_) => ({
            icon: "delete",
            tooltip: "Eliminar Reporte",
            onClick: (_, rowData: Report) => onRemoveReportHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Generar reporte",
            isFreeAction: true,
            onClick: onGenerateReportHandler,
          },
        ]}
      />
    </div>
  );
};

export default ManualReportList;
