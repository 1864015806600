import * as React from "react";
import Select from "react-select";

import { ModalProps } from "components/UI/Modal/ModalProvider";
import { Modal } from "components/UI";
import ModalBody from "components/UI/Modal/ModalBody";
import ModalFooter from "components/UI/Modal/ModalFooter";
import ModalHeader from "components/UI/Modal/ModalHeader";
import { ParamType } from "types/SettingType";
import {
  createSettingParamService,
  updateSettingParamService,
} from "services/SettingService";
import { PARAM_TYPE } from "constant/SettingConstants";

export interface ParamManagementModalProps {
  param?: ParamType;
  settingUUID?: string;
}

const ParamManagementModal: React.FC<
  ParamManagementModalProps & ModalProps
> = ({ onDismiss, onSubmit, param, settingUUID }) => {
  const submitButtonRef = React.useRef<HTMLInputElement>();
  const [checked, setChecked] = React.useState<boolean>(param?.value === "1");
  const [selectedType, setSelectedType] = React.useState<{
    label: string;
    value: PARAM_TYPE;
  }>(param ? { label: param.type, value: param.type } : null);
  const [state, setState] = React.useState<ParamType>({
    uuid: param?.uuid || "",
    key: param?.key || "",
    value: param?.value || "",
    details: param?.details || "",
    type: param?.type,
  });

  React.useEffect(() => {
    if (selectedType?.value !== PARAM_TYPE.BOOLEAN && checked) {
      console.log("entro2");
      setChecked(false);
      setState({
        ...state,
        value: "",
      });
    }
  }, [checked, state, selectedType]);

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmitHandler = async () => {
    let res;
    let val;

    if (selectedType?.value === PARAM_TYPE.BOOLEAN && checked) {
      val = "1";
    } else if (selectedType?.value === PARAM_TYPE.BOOLEAN && !checked) {
      val = "0";
    } else {
      val = state.value;
    }

    if (!param) {
      res = await createSettingParamService({
        key: state.key,
        details: state.details,
        settingUUID,
        type: selectedType.value,
        value: val,
      });
    } else {
      res = await updateSettingParamService({
        uuid: state.uuid,
        key: state.key,
        details: state.details,
        settingUUID,
        type: selectedType.value,
        value: val,
      });
    }

    onSubmit(res);
  };

  return (
    <Modal onDismiss={onDismiss}>
      <ModalHeader>
        {param ? "Editar Configuración" : "Nueva Configuración"}
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            <form
              className="space-y-6"
              onSubmit={async (e) => {
                e.preventDefault();
                await onSubmitHandler();
              }}
            >
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="key" className="sr-only">
                    Llave
                  </label>
                  <input
                    required
                    id="key"
                    name="key"
                    type="text"
                    value={state.key}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Llave"
                    onChange={onInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="value" className="sr-only">
                    Value
                  </label>
                  {selectedType?.value !== PARAM_TYPE.BOOLEAN ? (
                    <input
                      required
                      id="value"
                      name="value"
                      type="text"
                      value={state.value}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Valor"
                      onChange={onInputChange}
                    />
                  ) : (
                    <div className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm">
                      <input
                        id="value"
                        type="checkbox"
                        name="value"
                        defaultChecked={checked}
                        className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                        onClick={() => setChecked(!checked)}
                      />
                    </div>
                  )}
                </div>

                <div>
                  <label htmlFor="details" className="sr-only">
                    Detalles
                  </label>
                  <input
                    required
                    id="details"
                    name="details"
                    type="text"
                    value={state.details}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Detalles"
                    onChange={onInputChange}
                  />
                </div>

                <div className="relative h-44 pt-8">
                  <label htmlFor="type" className="sr-only">
                    Tipo
                  </label>
                  <Select
                    id="type"
                    defaultValue={null}
                    value={selectedType}
                    name="type"
                    options={Object.values(PARAM_TYPE).map((v: string) => ({
                      label: v,
                      value: v,
                    }))}
                    placeholder="Tipo"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(tp: any) => setSelectedType(tp)}
                  />
                  {!selectedType && (
                    <input
                      required
                      name="type"
                      className="outline-none focus:outline-none absolute"
                      style={{ height: 1, opacity: 0 }}
                    />
                  )}
                </div>
              </div>
              <div>
                <button
                  ref={submitButtonRef}
                  type="submit"
                  className="hidden"
                />
              </div>
            </form>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => submitButtonRef.current.click()}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onDismiss}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ParamManagementModal;
