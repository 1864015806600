import * as React from "react";

import Table from "components/UI/Table";
import { useParams } from "react-router-dom";
import { placeGroupPlacesService } from "services/PlaceGroupService";
import { PlaceResponseType } from "types/PlaceType";

interface PlaceGroupListProps {}

export const PlaceGroupList: React.FC<PlaceGroupListProps> = () => {
  const params = useParams();
  const [limit, setLimit] = React.useState<number>(25);

  return (
    <div className="h-full w-full">
      <Table
        title="Lista de sucursales"
        limit={limit}
        columns={[{ title: "Nombre", field: "name" }]}
        data={async (query) => {
          const res = (await placeGroupPlacesService({
            input: { uuid: params.placeGroupUUID, page: query.page + 1 },
          })) as PlaceResponseType;
          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
      />
    </div>
  );
};

export default PlaceGroupList;
