import { gql } from "@apollo/client";
import { deepCopy } from "libs";
import { authClient } from "libs/AuthClient";
import { PaginationParams } from "types/PaginationType";
import {
  AddRemoveItemToServiceServiceType,
  ServiceResponseType,
  ServiceType,
  AddRemoveServiceItemToPlaceType,
  CreateService,
  UpdateService,
} from "types/ServiceType";

const createServiceService = (createServiceData: CreateService) => {
  return authClient()
    .mutate({
      context: {
        hasUpload: true,
      },
      mutation: gql`
        mutation createService($input: CreateServiceInput!) {
          createService(input: $input)
        }
      `,
      variables: {
        input: {
          ...createServiceData,
        },
      },
    })
    .then(({ data }): Object => {
      const warehouseData = data.createService;

      return {
        data: warehouseData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const updateServiceService = (updateServiceData: UpdateService) => {
  return authClient()
    .mutate({
      context: {
        hasUpload: true,
      },
      mutation: gql`
        mutation updateService($input: UpdateServiceInput!) {
          updateService(input: $input)
        }
      `,
      variables: {
        input: {
          ...updateServiceData,
        },
      },
    })
    .then(({ data }): Object => {
      const warehouseData = data.updateService;

      return {
        data: warehouseData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const getServiceService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query getServices($input: BasePaginationInput!) {
          services(input: $input) {
            limit
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            docs {
              ... on Service {
                uuid
                name
                type
                imageUrl
                settings {
                  comission
                  comissionPercent
                  daysBeforeExpire
                  printDisclaimer
                  codeScan
                  scanOnly
                  inputMask
                }
              }
            }
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const warehouseData = data.services as ServiceResponseType;

      return {
        ...warehouseData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const getServiceItemsService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query getServicesItems($input: BasePaginationInput!) {
          serviceItems(input: $input) {
            limit
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            docs {
              ... on ServiceItem {
                uuid
                price
                item {
                  uuid
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const serviceItemData = data.serviceItems as ServiceResponseType;

      return {
        ...serviceItemData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const addRemoveServiceItemToServiceService = (
  addRemoveItemServiceDetails: AddRemoveItemToServiceServiceType
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation addRemoveServiceItemToService(
          $input: AddRemoveServiceItemToServiceInput!
        ) {
          addRemoveServiceItemToService(input: $input)
        }
      `,
      variables: {
        input: {
          ...addRemoveItemServiceDetails,
        },
      },
    })
    .then(({ data }): Object => {
      const serviceItem = data.addRemoveServiceItemToService;

      return {
        data: serviceItem,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const addRemoveServiceItemToPlaceService = (
  addRemoveServiceItemToPlaceDetails: AddRemoveServiceItemToPlaceType
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation addRemoveServiceItemToPlace(
          $input: AddRemoveServiceItemToPlaceInput!
        ) {
          addRemoveServiceItemToPlace(input: $input)
        }
      `,
      variables: {
        input: {
          ...addRemoveServiceItemToPlaceDetails,
        },
      },
    })
    .then(({ data }): Object => {
      const serviceItem = data.addRemoveServiceItemToPlace;

      return {
        data: serviceItem,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const getServiceQueryService = ({ query }: { query: string }) => {
  return authClient()
    .query({
      query: gql`
        query serviceQuery($query: String!) {
          serviceQuery(query: $query) {
            uuid
            name
            type
          }
        }
      `,
      variables: {
        query,
      },
    })
    .then(({ data }): Object => {
      const serviceData = data.serviceQuery as ServiceType[];

      return serviceData;
    });
};

const getServiceQueryByPlaceService = ({ query }: { query: string }) => {
  return authClient()
    .query({
      query: gql`
        query serviceQueryByPlace($query: String!) {
          serviceQueryByPlace(query: $query) {
            uuid
            name
            type
          }
        }
      `,
      variables: {
        query,
      },
    })
    .then(({ data }): Object => {
      const serviceData = data.serviceQueryByPlace as ServiceType[];

      return serviceData;
    });
};

export {
  getServiceService,
  getServiceQueryService,
  updateServiceService,
  createServiceService,
  getServiceItemsService,
  addRemoveServiceItemToServiceService,
  getServiceQueryByPlaceService,
  addRemoveServiceItemToPlaceService,
};
