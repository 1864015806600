import React from 'react';


interface NotFoundProps {
  message?: string
}

const NotFound: React.FC<NotFoundProps> = ({ message =  "Content Not Found :'("}) => {
  return (
    <div className="w-full text-center text-gray-500 text-xl">{message}</div>
  );
}

export default NotFound;