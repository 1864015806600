import * as React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";

import { ROLES, RouteConstants } from "constant";
import { getServiceService } from "services/ServiceService";
import { useModal } from "components/UI/Modal/ModalProvider";
import { ServiceResponseType, ServiceType } from "types/ServiceType";
import { AuthSelector } from "selectors/AuthSelector";
import Table from "components/UI/Table";
import ServiceManagementModal from "./ServiceManagementModal";
import { includesRoles } from "libs";

interface ServiceListProps {}

export const ServiceList: React.FC<ServiceListProps> = () => {
  const history = useHistory();
  const auth = useSelector(AuthSelector);
  const [limit, setLimit] = React.useState<number>(25);
  const serviceManagementModal = useModal(ServiceManagementModal);
  const [services, setServices] = React.useState<ServiceType[]>([]);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewServiceHandler = async () => {
    const res = await serviceManagementModal({ services });

    if (res?.data) {
      toast.success(res?.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onEditServiceHandler = async (service: ServiceType) => {
    if (service.settings) {
      service.settings.comissionPercent =
        service.settings.comissionPercent * 100;
    }

    const res = await serviceManagementModal({ service });

    if (res?.data) {
      toast.success(res?.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onServiceItemsHandler = async (service: ServiceType) => {
    history.push(
      RouteConstants.SERVICES_ITEMS.path.replace(":serviceUUID", service.uuid)
    );
  };

  const getActions = () => {
    return includesRoles({ userRoles: auth.roles, roles: [ROLES.SUPER_ADMIN] })
      ? [
          {
            icon: "edit",
            tooltip: "Editar servicio",
            onClick: (_: any, rowData: ServiceType) =>
              onEditServiceHandler(rowData),
          },
          {
            icon: "inventory",
            tooltip: "Administrar productos de servicio",
            onClick: (_: any, rowData: ServiceType) =>
              onServiceItemsHandler(rowData),
          },
          {
            icon: "add",
            tooltip: "Agregar Servicio",
            isFreeAction: true,
            onClick: onNewServiceHandler,
          },
        ]
      : [
          {
            icon: "add",
            tooltip: "Administrar Servicios",
            isFreeAction: true,
            onClick: onNewServiceHandler,
          },
        ];
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de servicios"
        limit={limit}
        columns={[
          { title: "Nombre", field: "name" },
          { title: "Categoria", field: "type" },
        ]}
        data={async (query) => {
          const res = (await getServiceService({
            input: { page: query.page + 1, query: query.search },
          })) as ServiceResponseType;

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);
          setServices(res.docs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={getActions()}
      />
    </div>
  );
};

export default ServiceList;
