import * as React from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { toast } from "react-toastify";

import { useModal } from "components/UI/Modal/ModalProvider";
import Table from "components/UI/Table";
import DeviceUserManagementModal from "./DeviceUserManagementModal";
import MessageModal from "components/UI/Message/MessageModal";
import {
  getDevicesServicesService,
  getDevicesUsersService,
  removeUserFromDeviceService,
} from "services/DeviceService";
import { UsersResponseType, UserType } from "types/UserType";

interface DeviceUserListProps {}

export const DeviceUserList: React.FC<DeviceUserListProps> = () => {
  const params = useParams();
  const messageModal = useModal(MessageModal);
  const [limit, setLimit] = React.useState<number>(25);
  const deviceUserManagementModal = useModal(DeviceUserManagementModal);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewDeviceUserHandler = async () => {
    const res = await deviceUserManagementModal({ ...params });

    if (res?.data) {
      toast.success(res?.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onRemoveDeviceUserHandler = async (user: UserType) => {
    const res = await messageModal({
      message: `Remover usuario ${user.username} del dispositivo?`,
      confirm: true,
    });
    if (res?.data) {
      const res2: any = await removeUserFromDeviceService({
        deviceUUID: params.deviceUUID,
        userUUID: user.uuid,
      });

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title={`Lista de usuarios en dispositivo`}
        limit={limit}
        columns={[
          { title: "Nombre", field: "username" },
          {
            title: "Usuario activo",
            field: "active",
            render: (rowData: UserType) => (
              <span>{rowData.active ? "Si" : "No"}</span>
            ),
          },
        ]}
        data={async (query) => {
          const res = (await getDevicesUsersService({
            input: {
              uuid: params.deviceUUID,
              page: query.page + 1,
              query: query.search,
            },
          })) as UsersResponseType;
          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          {
            icon: "delete",
            tooltip: "Eliminar usuario",
            onClick: (_, rowData: UserType) =>
              onRemoveDeviceUserHandler(rowData),
          },
          {
            icon: "add",
            tooltip: "Agregar usuario",
            isFreeAction: true,
            onClick: onNewDeviceUserHandler,
          },
        ]}
      />
    </div>
  );
};

export default DeviceUserList;
