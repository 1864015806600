import * as React from 'react';
import { toast } from 'react-toastify';

import Select, { OptionsType } from 'react-select';
import { ITEM_TYPE } from 'constant';
import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import { ItemType } from 'types/ItemType';
import { createItemService, getItemTypesService, updateItemService } from 'services/ItemService';


export interface ItemManagementModalProps {
  item?: ItemType
}

const ItemManagementModal: React.FC<ItemManagementModalProps & ModalProps> = ({ onDismiss, onSubmit, item }) => {
  const [file, setFile] = React.useState<File>(null);
  const submitButtonRef = React.useRef<HTMLInputElement>();
  const [types, setTypes] = React.useState<OptionsType<any>>([]);
  const [selectedType, setSelectedType] = React.useState<{ label: string, value: any }>(null);
  const [state, setState] = React.useState<ItemType>({
    uuid: item?.uuid || '',
    name: item?.name || '',
    sku: item?.sku || '',
    type: item?.type ?? ITEM_TYPE.SINGLE,
    details: item?.details || '',
    imageUrl: item?.imageUrl || '',
    available: item?.available || false
  });

  React.useEffect(() => {
    getItemTypesService()
      .then((res: ITEM_TYPE[]) => {
        const optionTypes = res.map(r => ({ label: r, value: r }));

        setTypes(optionTypes);;
      });

    if (item && !selectedType) {
      setSelectedType({ label: item.type, value: item.type } as any)
    }

  }, [item, selectedType]);

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    setState({
      ...state,
      [name]: value
    });
  }

  const onSubmitHandler = async () => {
    let res;

    if (!item) {
      res = await createItemService({ 
        name: state.name,
        sku: state.sku,
        available: state.available,
        details: state.details,
        type: selectedType.value,
        file
      });
    } else {
      res = await updateItemService({ 
        uuid: state.uuid,
        name: state.name,
        sku: state.sku,
        available: state.available,
        details: state.details,
        type: selectedType.value, 
        file
      });
    }

    onSubmit(res);
  }

  const onFileChange = (e: React.SyntheticEvent) => {
    const element = e.currentTarget as HTMLInputElement;
    const file = element.files[0];
    if (file) {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 3) {
        toast.error('La imagen excede el tamaño');
      } else {
        setFile(file);
      }
    }
  }


  return (
    <Modal onDismiss={onDismiss} >
      <ModalHeader>
        {item ? "Editar Producto" : 'Nuevo Producto'}
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            <form className="space-y-6" onSubmit={async e => {
              e.preventDefault();
              await onSubmitHandler();
            }}>

              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="name" className="sr-only">Nombre del producto</label>
                  <input
                    required
                    id="name"
                    name="name"
                    type="text"
                    value={state.name}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Nombre del producto"
                    onChange={onInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="sku" className="sr-only">SKU</label>
                  <input
                    required
                    id="sku"
                    name="sku"
                    type="text"
                    value={state.sku}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="SKU"
                    onChange={onInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="details" className="sr-only">Detalles</label>
                  <input
                    id="details"
                    name="details"
                    type="text"
                    value={state.details}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Detalles"
                    onChange={onInputChange}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="type" className="sr-only">Tipo</label>
                <Select
                  id="type"
                  defaultValue={null}
                  value={selectedType}
                  name="type"
                  options={types}
                  placeholder="Tipos"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={value => setSelectedType(value)}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-4">
                  Imagen
                </label>
                <div className="flex flex-row items-center">
                  {item?.imageUrl || file ? (
                    <img
                      src={item?.imageUrl ? `${process.env.UPLOAD_BE_BASE_PATH}${item.imageUrl}` : file && URL.createObjectURL(file)}
                      alt="service logo"
                      className="h-12 w-12 rounded-full"
                    />
                  ) : (
                    <span className="flex h-12 w-12 bg-gray-400 rounded-full text-gray-300 text-xl items-center justify-center">?</span>
                  )}
                  <label htmlFor="file-upload" className="ml-4 relative cursor-pointer bg-white rounded-md font-medium text-red-400 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500">
                    <span>Subir imagen</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={onFileChange}
                    />
                  </label>
                </div>
              </div>

              <div className="mb-28">
                <label className="flex items-center space-x-3 mt-2">
                  <input
                    type="checkbox"
                    name="active"
                    defaultChecked={state.available}
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                    onClick={() => setState({ ...state, available: !state.available })}
                  />
                  <span className="text-gray-500 font-medium">Disponible</span>
                </label>
              </div>

              <div>
                <button ref={submitButtonRef} type="submit" className="hidden" />
              </div>

            </form>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => submitButtonRef.current.click()}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onDismiss}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default ItemManagementModal;
