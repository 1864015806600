import { gql } from "@apollo/client";
import { deepCopy } from "libs";
import { authClient } from "libs/AuthClient";
import { CronJobCreate, CronJobRemove, CronJobUpdate } from "types/CronJob";
import { PaginationParams } from "types/PaginationType";

const createCronjobService = (cronJobData: CronJobCreate) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation createCronJob($input: CreateCronJobInput!) {
          createCronJob(input: $input)
        }
      `,
      variables: {
        input: {
          ...cronJobData,
        },
      },
    })
    .then(({ data }): Object => {
      const cronJobData = data.createCronJob;

      return {
        data: cronJobData,
      };
    });
};

const updateCronjobService = (cronJobData: CronJobUpdate) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation updateCronJob($input: UpdateCronJobInput!) {
          updateCronJob(input: $input)
        }
      `,
      variables: {
        input: {
          ...cronJobData,
        },
      },
    })
    .then(({ data }): Object => {
      const cronJobData = data.updateCronJob;

      return {
        data: cronJobData,
      };
    });
};

const removeCronjobService = (cronJobData: CronJobRemove) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation removeCronJob($uuid: String!) {
          removeCronJob(uuid: $uuid)
        }
      `,
      variables: {
        ...cronJobData,
      },
    })
    .then(({ data }): Object => {
      const cronJobData = data.removeCronJob;

      return {
        data: cronJobData,
      };
    });
};

const cronJobsService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query cronJobs($input: BasePaginationInput!) {
          cronJobs(input: $input) {
            docs {
              ... on CronJob {
                uuid
                name
                key
                status
                scheduleString
                timeZone
              }
            }
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            limit
          }
        }
      `,
      variables: {
        ...input
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const cronJobsData = data.cronJobs;

      return {
        ...cronJobsData,
      };
    });
};

export {
  createCronjobService,
  updateCronjobService,
  removeCronjobService,
  cronJobsService,
};
