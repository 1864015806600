import * as React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { MenuList } from "constant";
import { RenderRoutes } from "routing";
import { authClient } from "libs/AuthClient";
import { tokenLoginAction } from "actions/AuthActions";
import { AuthSelector } from "selectors/AuthSelector";

import ModalProvider from "components/UI/Modal/ModalProvider";
import { NavBar, SideBar, Content, Spinner } from "components/UI";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

interface AppProps {}

const App: React.FC<AppProps> = () => {
  const dispatch = useDispatch();
  const { loggedIn, roles } = useSelector(AuthSelector);
  const routes = RenderRoutes({ loggedIn, roles });
  const [expandedSideBar, setExpandedSideBar] = React.useState<boolean>(true);

  React.useEffect(() => {
    dispatch(tokenLoginAction());
  }, []);

  return (
    <ApolloProvider client={authClient()}>
      <ModalProvider>
        <React.Suspense
          fallback={<Spinner className="h-12 w-12 text-gray-400" />}
        >
          <Router>
            {loggedIn ? (
              <div
                key={loggedIn ? "true" : "false"}
                className="relative flex flex-row h-full"
              >
                <SideBar items={MenuList} expandedSideBar={expandedSideBar} />
                <div
                  className={`${
                    expandedSideBar ? "w-5/6 flex-1" : "w-full"
                  } flex flex-col`}
                >
                  <NavBar
                    onExpand={() => setExpandedSideBar(!expandedSideBar)}
                  />
                  <Content>
                    <Switch>{routes}</Switch>
                  </Content>
                </div>
              </div>
            ) : (
              <div className="w-full h-full">
                <Switch>{routes}</Switch>
              </div>
            )}
          </Router>
          <ToastContainer autoClose={2000} />
        </React.Suspense>
      </ModalProvider>
    </ApolloProvider>
  );
};

export default App;
