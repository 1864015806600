import React from 'react';

import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import ModalBody from './ModalBody';
import { ModalProps } from './ModalProvider';


export enum ModalVariant {
  lg = 'w-full sm:w-4/6',
  md = 'w-full sm:w-1/2',
  full = 'w-full',
}

export type ModalVariantTokens = keyof typeof ModalVariant;

interface Props {
  children: React.ReactNodeArray,
  variant?: ModalVariantTokens
}

const renderChildrenType = (children: React.ReactNodeArray, Component: React.FC) =>
  children.find((child: any) => child.type === Component)

export const Modal: React.FC<ModalProps & Props> = ({ children, onDismiss, variant }) => {
  return (
    <div className="fixed z-30 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-64 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={() => onDismiss && onDismiss()}></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${ModalVariant[variant] || 'w-full sm:w-1/3'}`}>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* <div className="sm:flex sm:items-start"> */}
            <div className="w-full">
              {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div> */}
              <div className="w-full">
                {renderChildrenType(children, ModalHeader)}
                {renderChildrenType(children, ModalBody)}
              </div>
            </div>
          </div>
          {renderChildrenType(children, ModalFooter)}
        </div>
      </div>
    </div>

  );
};


export default Modal;