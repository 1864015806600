import * as React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import { convertToRaw, Editor, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import { toast } from 'react-toastify';


import { includesRoles, isOnlyNumber, removeKeysFromObject, resolveFileUploads } from 'libs';
import { ROLES } from 'constant';
import { addRemoveServiceItemToPlaceService, createServiceService, getServiceQueryService, updateServiceService } from 'services/ServiceService';
import { AuthSelector } from 'selectors/AuthSelector';
import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import { ServiceType } from 'types/ServiceType';
import { SERVICE_TYPE } from 'constant/ServiceConstants';

import 'draft-js/dist/Draft.css';
export interface ServiceManagementModalProps {
  service?: ServiceType,
  services?: ServiceType[]
}

const ServiceManagementModal: React.FC<ServiceManagementModalProps & ModalProps> = ({ onDismiss, onSubmit, service, services }) => {
  const auth = useSelector(AuthSelector);
  const submitButtonRef = React.useRef<HTMLInputElement>();
  const [file, setFile] = React.useState<File>(null);
  const [selectedType, setSelectedType] = React.useState<{ label: string, value: SERVICE_TYPE }>(
    service ? { label: service.type, value: service.type } : null
  );
  const [selectedServices, setSelectedServices] = React.useState<{ label: string, value: ServiceType }[]>([])
  const [state, setState] = React.useState<ServiceType>({
    uuid: service?.uuid || '',
    name: service?.name || '',
    type: service?.type || null,
    settings: service?.settings || null
  });
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const isAdmin = includesRoles({ userRoles: auth.roles, roles: [ROLES.SUPER_ADMIN] });

  React.useEffect(() => {
    if (services) {
      setSelectedServices(services.map(s => ({
        label: s.name,
        value: s
      })));
    }

    if (service?.settings?.printDisclaimer) {
      setEditorState(EditorState.createWithContent(stateFromHTML(service.settings.printDisclaimer)));
    }
  }, [services, service])

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;
    let s = {
      ...state.settings,
    };

    if (['comission', 'daysBeforeExpire', 'comissionPercent'].includes(name)) {
      s = {
        ...s,
        [name]: value.match(/^[0-9]*\.?[0-9]*$/) ? value : ''
      }
    }
    else if (name === 'inputMask') {
      s = {
        ...s,
        [name]: value
      }
    }

    setState({
      ...state,
      settings: s,
      [name]: value
    });
  }

  const onSubmitHandler = async () => {
    let res;

    if (isAdmin) {
      let settings = state.settings;
      
      settings.comission = parseFloat(`${settings.comission}`);
      settings.daysBeforeExpire = parseInt(`${settings.daysBeforeExpire}`);
      settings.comissionPercent = parseFloat(`${settings.comissionPercent}`);

      settings = {
        ...state.settings,
        printDisclaimer: draftToHtml(convertToRaw(editorState.getCurrentContent()))
      };

      if (!service) {
        res = await createServiceService({
          name: state.name,
          type: selectedType.value,
          settings: removeKeysFromObject(settings, ['__typename']),
          file
        });
      } else {
        res = await updateServiceService({
          uuid: state.uuid,
          name: state.name,
          type: selectedType.value,
          settings: removeKeysFromObject(settings, ['__typename']),
          file
        });
      }
    } else {
      res = await addRemoveServiceItemToPlaceService({
        serviceUUIDS: selectedServices.map(sService => ({ uuid: sService.value.uuid }))
      })
    }

    onSubmit(res);
  }

  const loadServices = (inputValue: string) => {
    return getServiceQueryService({ query: inputValue })
      .then((services: ServiceType[]) => {
        return services.map(service => ({
          label: service.name,
          value: service
        }))
          .filter(service => !selectedServices.some(ss => ss.label === service.label));
      });
  };

  const onFileChange = (e: React.SyntheticEvent) => {
    const element = e.currentTarget as HTMLInputElement;
    const file = element.files[0];
    if (file) {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 3) {
        toast.error('La imagen excede el tamaño');
      } else {
        setFile(file);
      }
    }
  }

  return (
    <Modal onDismiss={onDismiss} variant="lg">
      <ModalHeader>
        {service
          ? "Editar servicio"
          : !isAdmin
          ? "Administrar Servicios"
          : "Nuevo servicio"}
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full h-auto">
          <div className="flex flex-col w-full">
            <form
              className="space-y-6"
              onSubmit={async (e) => {
                e.preventDefault();
                await onSubmitHandler();
              }}
            >
              {isAdmin ? (
                <>
                  <div>
                    <label htmlFor="name" className="sr-only">
                      Nombre de servicio
                    </label>
                    <input
                      required
                      id="name"
                      name="name"
                      type="text"
                      value={state.name}
                      className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Nombre de servicio"
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="relative">
                    <label htmlFor="item" className="sr-only">
                      Tipo
                    </label>
                    <Select
                      id="item"
                      name="item"
                      placeholder="Tipo"
                      className={`basic-multi-select ${
                        isAdmin ? "" : "mb-24 sm:mb-44"
                      }`}
                      classNamePrefix="select"
                      options={Object.values(SERVICE_TYPE).map((v) => ({
                        label: v,
                        value: v,
                      }))}
                      defaultValue={selectedType}
                      value={selectedType}
                      onChange={(type) => setSelectedType(type as any)}
                    />
                    {!selectedType && (
                      <input
                        required
                        name="item"
                        className="outline-none focus:outline-none absolute"
                        style={{ height: 1, opacity: 0 }}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className="">
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions={selectedServices}
                    value={selectedServices}
                    loadOptions={loadServices}
                    onChange={(items) => setSelectedServices(items as any)}
                  />
                </div>
              )}
              <div>
                <button
                  ref={submitButtonRef}
                  type="submit"
                  className="hidden"
                />
              </div>
              {isAdmin && (
                <>
                  <div>
                    <label htmlFor="comission" className="text-gray-500">
                      Comisión a aplicar
                    </label>
                    <input
                      id="comission"
                      name="comission"
                      type="text"
                      value={state.settings?.comission ?? ''}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      onChange={onInputChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="comissionPercent" className="text-gray-500">
                      Porcentaje de comisión a aplicar
                    </label>
                    <input
                      id="comissionPercent"
                      name="comissionPercent"
                      type="text"
                      value={state.settings?.comissionPercent ?? ''}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      onChange={onInputChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="daysBeforeExpire" className="text-gray-500">
                      Dias de pago permitidos antes de expiración
                    </label>
                    <input
                      id="daysBeforeExpire"
                      name="daysBeforeExpire"
                      type="text"
                      value={state.settings?.daysBeforeExpire ?? ''}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      onChange={onInputChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="inputMask" className="text-gray-500">
                      Mensaje de campo de captura
                    </label>
                    <input
                      id="inputMask"
                      name="inputMask"
                      type="text"
                      value={state.settings?.inputMask || ""}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      onChange={onInputChange}
                    />
                  </div>

                  <div className="mb-28">
                    <label className="flex items-center space-x-3 mt-2">
                      <input
                        type="checkbox"
                        name="active"
                        defaultChecked={state.settings?.codeScan}
                        className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                        onClick={() =>
                          setState({
                            ...state,
                            settings: {
                              ...state.settings,
                              codeScan: !state.settings?.codeScan,
                            },
                          })
                        }
                      />
                      <span className="text-gray-500 font-medium">Habilitar escáner</span>
                    </label>
                  </div>

                  <div className="mb-28">
                    <label className="flex items-center space-x-3 mt-2">
                      <input
                        type="checkbox"
                        name="active"
                        defaultChecked={state.settings?.scanOnly}
                        className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                        onClick={() =>
                          setState({
                            ...state,
                            settings: {
                              ...state.settings,
                              scanOnly: !state.settings?.scanOnly,
                            },
                          })
                        }
                      />
                      <span className="text-gray-500 font-medium">Habilitar solo escáner</span>
                    </label>
                  </div>

                  <div>
                    <label htmlFor="printDisclaimer" className="text-gray-500">
                      Mensaje de descargo de responsabilidad en impresión
                    </label>
                    <div className="border border-gray-300 rounded h-32 overflow-y-auto p-2">
                      <Editor
                        editorState={editorState}
                        onChange={setEditorState}
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-4">
                      Logo de servicio
                    </label>
                    <div className="flex flex-row items-center">
                      {service?.imageUrl || file ? (
                        <img
                          src={
                            service?.imageUrl
                              ? resolveFileUploads(service.imageUrl)
                              : file && URL.createObjectURL(file)
                          }
                          alt="service logo"
                          className="h-12 w-12 rounded-full"
                        />
                      ) : (
                        <span className="flex h-12 w-12 bg-gray-400 rounded-full text-gray-300 text-xl items-center justify-center">
                          ?
                        </span>
                      )}
                      <label
                        htmlFor="file-upload"
                        className="ml-4 relative cursor-pointer bg-white rounded-md font-medium text-red-400 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
                      >
                        <span>Subir imagen</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={onFileChange}
                        />
                      </label>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => submitButtonRef.current.click()}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onDismiss}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default ServiceManagementModal;
