import { gql } from "@apollo/client";
import { deepCopy } from "libs";
import { authClient } from "libs/AuthClient";
import { PaginationParams } from "types/PaginationType";
import {
  SettingType,
  SettingCreateType,
  SettingUpdateType,
  SettingParamCreateType,
  SettingParamUpdateType,
  SettingParamRemoveType,
} from "types/SettingType";

const createSettingService = (setting: SettingCreateType) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation createSetting($input: CreateSettingInput!) {
          createSetting(input: $input)
        }
      `,
      variables: {
        input: {
          ...setting,
        },
      },
    })
    .then(() => {
      return {
        data: "Item added!",
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const updateSettingService = (setting: SettingUpdateType) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation updateSetting($input: UpdateSettingInput!) {
          updateSetting(input: $input)
        }
      `,
      variables: {
        input: {
          ...setting,
        },
      },
    })
    .then(() => {
      return {
        data: "Setting updated!",
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const removeSettingService = ({ uuid }: Pick<SettingType, "uuid">) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation removeSetting($uuid: String!) {
          removeSetting(uuid: $uuid)
        }
      `,
      variables: {
        uuid,
      },
    })
    .then(() => {
      return {
        data: "Setting Removed!",
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const createSettingParamService = (settingParam: SettingParamCreateType) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation createSettingParam($input: CreateSettingParamInput!) {
          createSettingParam(input: $input)
        }
      `,
      variables: {
        input: {
          ...settingParam,
        },
      },
    })
    .then(() => {
      return {
        data: "Setting param added!",
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const updateSettingParamService = (settingParam: SettingParamUpdateType) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation updateSettingParam($input: UpdateSettingParamInput!) {
          updateSettingParam(input: $input)
        }
      `,
      variables: {
        input: {
          ...settingParam,
        },
      },
    })
    .then(() => {
      return {
        data: "Setting param updated!",
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const removeSettingParamService = (settingParam: SettingParamRemoveType) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation removeSettingParam($input: RemoveSettingParamInput!) {
          removeSettingParam(input: $input)
        }
      `,
      variables: {
        input: {
          ...settingParam,
        },
      },
    })
    .then(() => {
      return {
        data: "Setting param removed!",
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const settingsService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query settings($input: BasePaginationInput!) {
          settings(input: $input) {
            limit
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            docs {
              ... on Setting {
                uuid
                name
                code
              }
            }
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const settingsData = data.settings;

      return settingsData;
    });
};

const settingParamsService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query settingParams($input: BasePaginationInput!) {
          settingParams(input: $input) {
            limit
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            docs {
              ... on Param {
                uuid
                key
                value
                details
                type
              }
            }
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const settingsParamsData = data.settingParams;

      return settingsParamsData;
    });
};

export {
  settingsService,
  createSettingService,
  updateSettingService,
  removeSettingService,
  settingParamsService,
  createSettingParamService,
  updateSettingParamService,
  removeSettingParamService,
};
