import { gql } from "@apollo/client";
import { deepCopy } from "libs";
import { authClient } from "libs/AuthClient";
import { PaginationParams } from "types/PaginationType";
import {
  UserCreate,
  UsersResponseType,
  UserType,
  UserUpdate,
} from "types/UserType";

const usersService = ({
  page = 1,
  query,
}: {
  page: number;
  query?: string;
}) => {
  return authClient()
    .query({
      query: gql`
        query getUsers($input: BasePaginationInput!) {
          users(input: $input) {
            docs {
              ... on User {
                uuid
                username
                active
                rolesPermissions {
                  roles
                  permissions
                }
                places {
                  uuid
                  name
                  code
                }
              }
            }
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            limit
          }
        }
      `,
      variables: {
        input: {
          page,
          query,
        },
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): any => {
      let usersData = data.users as UsersResponseType;

      return {
        ...usersData,
      };
    });
};

const getUsersPlaceQueryService = ({ query }: { query: string }) => {
  return authClient()
    .query({
      query: gql`
        query userPlaceQuery($query: String!) {
          userPlaceQuery(query: $query) {
            uuid
            username
          }
        }
      `,
      variables: {
        query,
      },
    })
    .then(({ data }): Object => {
      const usersData = data.userPlaceQuery as UserType[];

      return usersData;
    });
};

const createUserService = (userInfo: UserCreate) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation createUser($input: CreateUserInput!) {
          createUser(input: $input)
        }
      `,
      variables: {
        input: {
          ...userInfo,
        },
      },
    })
    .then(({ data }): Object => {
      const userInfo = data.createUser;

      return {
        data: userInfo,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const getUserRolesPermissionsService = ({ uuid }: Pick<UserType, "uuid">) => {
  return authClient()
    .query({
      query: gql`
        query getUserRolesPermissions($uuid: String!) {
          getUserRolesPermissions(uuid: $uuid) {
            roles
            permissions
          }
        }
      `,
      variables: {
        uuid,
      },
    })
    .then(({ data }): Object => {
      const rolesPermissions = data.getUserRolesPermissions;

      return {
        ...rolesPermissions,
      };
    });
};

const updateUserService = (userInfo: UserUpdate) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation updateUser($input: UpdateUserInput!) {
          updateUser(input: $input)
        }
      `,
      variables: {
        input: {
          ...userInfo,
        },
      },
    })
    .then(({ data }): Object => {
      const userInfo = data.updateUser;

      return {
        data: userInfo,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

export {
  usersService,
  createUserService,
  getUserRolesPermissionsService,
  updateUserService,
  getUsersPlaceQueryService,
};
