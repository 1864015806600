import * as React from "react";

interface ContentProps {}

export const Content: React.FC<ContentProps> = ({ children }) => {
  return (
    <div
      className="w-full flex-1 p-2 bg-gray-100 overflow-y-auto"
      style={{
        height: "calc(100vh - 100px)",
      }}
    >
      {children}
    </div>
  );
};

export default Content;
