import * as React from "react";
import { DateTime } from "luxon";
import NumberFormat from "react-number-format";

import Table from "components/UI/Table";
import { ordersService, regenerateTicketService } from "services/OrderService";
import { OrderResponseType, OrderType } from "types/OrderType";
import { resolveFileUploads } from "libs";
import { useModal } from "components/UI/Modal/ModalProvider";
import MessageModal from "components/UI/Message/MessageModal";
import Loading from "components/UI/Modal/Loading";
import { toast } from "react-toastify";

interface OrderReportListProps { }

const OrderReportList: React.FC<OrderReportListProps> = () => {
  const [limit, setLimit] = React.useState<number>(25);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );
  const modalButtonRef = React.useRef<HTMLButtonElement>(null);
  const messageModal = useModal(MessageModal);
  const loadingModal = useModal(Loading);

  const openReportHandler = (order: OrderType) => {
    window.open(resolveFileUploads(order.documentUrl), "_blank");
  };

  const regeneratePDFHandler = async (order: OrderType) => {
    const res = await messageModal({
      message: "Regenerar ticket para esta ordern?",
      confirm: true,
    });
    if (res?.data) {
      loadingModal({ modalButtonRef, message: "Regenerando ticket" });
      const res2: any = await regenerateTicketService({ uuid: order.uuid });

      modalButtonRef.current.click();

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  const getData = async (query: { page: number, search: string }) => {
    const res = (await ordersService({
      input: { page: query.page + 1, query: query.search },
    })) as OrderResponseType;

    setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

    return {
      data: res.docs,
      page: res.page - 1,
      totalCount: res.totalDocs,
    };
  }

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de ordenes"
        limit={limit}
        columns={[
          {
            title: "# Orden", field: "orderNumber", cellStyle: {
              whiteSpace: 'nowrap'
            },
          },
          {
            title: "Servicio(s)",
            field: "refItems",
            render: (rowData: OrderType) => (
              <span>
                {rowData.refItems.reduce(
                  (acc, item) => (acc += item.serviceName),
                  ""
                )}
              </span>
            ),
          },
          {
            title: "Referencia",
            render: (rowData: OrderType) => (
              <div className="w-32 overflow-x-auto">{rowData.reference}</div>
            ),
          },
          { title: "Status", field: "status" },
          { title: "Divisa", field: "currency" },
          { title: "Pago", field: "paymentType" },
          {
            title: "Monto",
            render: (rowData: OrderType) => (
              <NumberFormat
                value={rowData.amount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            ),
          },
          {
            title: "Comisión",
            render: (rowData: OrderType) => (
              <NumberFormat
                value={rowData.refItems[0]?.serviceComission ?? 0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            ),
          },
          {
            title: "Comisón Banco",
            render: (rowData: OrderType) => (
              <NumberFormat
                value={rowData.refItems[0]?.cardComission ?? 0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            ),
          },
          {
            title: "Sucursal", field: "place.name", cellStyle: {
              whiteSpace: 'nowrap'
            },
          },
          { title: "Dispositivo", field: "device.code" },
          { title: "Usuario", field: "user.username" },
          {
            title: "Fecha de pago",
            field: "paidAt",
            render: (rowData: OrderType) => (
              <span>
                {DateTime.fromISO(rowData.paidAt).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}
              </span>
            ),
          },
        ]}
        data={getData}
        actions={[
          (_) => ({
            icon: "pageview",
            tooltip: "Previsualizar Ticket",
            onClick: (_, rowData: OrderType) => openReportHandler(rowData),
          }),
          (_) => ({
            icon: "picture_as_pdf",
            tooltip: "Generar Ticket",
            onClick: (_, rowData: OrderType) => regeneratePDFHandler(rowData),
          }),
        ]}
      />
    </div>
  );
};

export default OrderReportList;
