import * as React from 'react';
import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import { SettingType } from 'types/SettingType';
import { createSettingService, updateSettingService } from 'services/SettingService';


export interface SettingManagementModalProps {
  setting?: SettingType
}

const SettingManagementModal: React.FC<SettingManagementModalProps & ModalProps> = ({ onDismiss, onSubmit, setting }) => {
  const submitButtonRef = React.useRef<HTMLInputElement>();
  const [state, setState] = React.useState<SettingType>({
    uuid: setting?.uuid || '',
    name: setting?.name || '',
    code: setting?.code || '',
  });

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    setState({
      ...state,
      [name]: value
    });
  }

  const onSubmitHandler = async () => {
    let res;

    if (!setting) {
      res = await createSettingService({ 
        name: state.name,
        code: state.code,
      });
    } else {
      res = await updateSettingService({ 
        uuid: state.uuid,
        name: state.name,
        code: state.code,
      });
    }

    onSubmit(res);
  }

  return (
    <Modal onDismiss={onDismiss} >
      <ModalHeader>
        {setting ? "Editar Configuración" : 'Nueva Configuración'}
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            <form className="space-y-6" onSubmit={async e => {
              e.preventDefault();
              await onSubmitHandler();
            }}>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="name" className="sr-only">Nombre de la configuración</label>
                  <input
                    required
                    id="name"
                    name="name"
                    type="text"
                    value={state.name}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Nombre de la configuración"
                    onChange={onInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="code" className="sr-only">Código</label>
                  <input
                    required
                    id="code"
                    name="code"
                    type="text"
                    value={state.code}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Código"
                    onChange={onInputChange}
                  />
                </div>

              </div>
              <div>
                <button ref={submitButtonRef} type="submit" className="hidden" />
              </div>
            </form>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => submitButtonRef.current.click()}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onDismiss}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default SettingManagementModal;
