import { AUTH_ACTION, ROLES } from "constant";
import { includesRoles } from "libs";
import { storeSession } from "libs/AuthClient";
import { Dispatch } from "react";
import { toast } from "react-toastify";
import { logoutService, tokenLoginService } from "services/AuthService";
import { AuthType } from "types/AuthType";


const checkLogin = (res: any) => {
  if (!res.user) {
    throw Error(res.message as any);
  }

  const { user, roles, permissions, place, token } = res;
  const isAdminLoggeable = includesRoles({ userRoles: roles, roles: [ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.SUPER_ADMIN] });

  if (!isAdminLoggeable) {
    throw Error("Access denied!");
  }

  storeSession({ loginFetched: true });

  return { 
    token,
    user, 
    roles, 
    permissions, 
    place
  }
}

const loginAction = ({ user, roles, permissions, place, token }: AuthType) => (dispatch: Dispatch<any>) => {
  storeSession({ loginFetched: true });

  return dispatch({
    type: AUTH_ACTION.LOGIN_SUCCESS,
    user,
    roles,
    permissions,
    place,
    token
  });
};

const tokenLoginAction = () => (dispatch: Dispatch<any>) => {
  return tokenLoginService()
    .then((res: AuthType) => {
      const validData = checkLogin(res);

      return dispatch({
        type: AUTH_ACTION.LOGIN_SUCCESS,
        ...validData
      });
    })
    .catch(({ message }) => {
      toast.error(message);
      return dispatch({
        type: AUTH_ACTION.LOGOUT
      });
    });
};

const logoutAction = () => (dispatch: Dispatch<any>) => {
  return logoutService().then(() => {
    return dispatch({
      type: AUTH_ACTION.LOGOUT
    });
  })
};

export {
  checkLogin,
  loginAction,
  tokenLoginAction,
  logoutAction
}