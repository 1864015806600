
export const RouteConstants = {
	LOGIN: { 
		key: 'login',
		name: 'Login',
		path: '/login'
	},
	DASHBOARD: {
		key: 'dashboard', 
		name: 'Dashboard',
		path: '/dashboard'
	},
	ADMINISTRATION: { 
		key: 'administration',
		name: 'Administración',
		path: '/administration'
	},
	USERS: { 
		key: 'users',
		name: 'Usuarios',
		path: '/administration/users'
	},
	PLACES: { 
		key: 'places',
		name: 'Sucursales',
		path: '/administration/places'
	},
	PLACE_GROUP: { 
		key: 'place_group',
		name: 'Grupo de Sucursales',
		path: '/administration/place-group'
	},
	PLACE_GROUP_PLACES: { 
		key: 'place_group_places',
		name: 'Sucursales En Grupo',
		path: '/administration/place-group/:placeGroupUUID'
	},
	WAREHOUSES: { 
		key: 'warehouses',
		name: 'Almacenes',
		path: '/administration/warehouses'
	},
  ITEMS: { 
		key: 'items',
		name: 'Catalogo de productos',
		path: '/administration/items'
	},
	WAREHOUSES_ITEMS: { 
		key: 'warehouses_items',
		name: 'Items de Almacene',
		path: '/administration/warehouses/:warehouseUUID/items'
	},
	SERVICES: { 
		key: 'services',
		name: 'Servicios',
		path: '/administration/services'
	},
	SERVICES_ITEMS: { 
		key: 'services_items',
		name: 'Items del servicio',
		path: '/administration/services/:serviceUUID/items'
	},
	DEVICES: { 
		key: 'devices',
		name: 'Dispositivos',
		path: '/administration/devices'
	},
	DEVICES_SERVICES: { 
		key: 'devices_services',
		name: 'Dispositivos',
		path: '/administration/devices/:deviceUUID/services'
	},
	DEVICES_USERS: { 
		key: 'devices_users',
		name: 'Dispositivos',
		path: '/administration/devices/:deviceUUID/users'
	},
	REPORTS: {
		key: 'reports',
		name: 'Reportes',
		path: '/reports'
	},
  ORDERS: { 
		key: 'orders',
		name: 'Ordenes',
		path: '/reports/orders'
	},
  MANUAL_REPORT: { 
		key: 'manual_report',
		name: 'Reporte manual',
		path: '/reports/manual-reports'
	},
  BILLING_REPORT: { 
		key: 'billing_report',
		name: 'Cobranza',
		path: '/reports/billing-reports'
	},
  COMISSION_REPORT: { 
		key: 'comission_report',
		name: 'Comisiones',
		path: '/reports/comission-reports'
	},
  INVOICE_REPORT: { 
		key: 'invoice_report',
		name: 'Facturación',
		path: '/reports/invoice-reports'
	},
  SETTINGS: { 
		key: 'setting',
		name: 'Configuraciones',
		path: '/settings'
	},
  PARAMETERS: { 
		key: 'params',
		name: 'Parámetros',
		path: '/settings/:settingUUID/params'
	},
  CRON: { 
		key: 'cron',
		name: 'Cron Jobs',
		path: '/cron-jobs'
	},
};
