import * as React from "react";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

import { useModal } from "components/UI/Modal/ModalProvider";
import Table from "components/UI/Table";
import CronJobManagementModal from "components/CronJob/CronJobManagementModal";
import { cronJobsService } from "services/CronJobService";
import { CronJob, CronJobResponse } from "types/CronJob";

interface CronJobListProps {}

const CronJobList: React.FC<CronJobListProps> = () => {
  const [limit, setLimit] = React.useState<number>(25);
  const cronJobManagementModal = useModal(CronJobManagementModal);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewCronJobHanlder = async () => {
    const res = await cronJobManagementModal();

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onEditCronJobHandler = async (cronJob: CronJob) => {
    const res = await cronJobManagementModal({ cronJob });

    if (res?.data) {
      toast.success(res.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de Cron Jobs"
        limit={limit}
        columns={[
          { title: "Nombre", field: "name" },
          { title: "Llave", field: "key" },
          { title: "Estado", field: "status" },
          { title: "Cadena De Tarea", field: "scheduleString" },
          { title: "Zona Horaria", field: "timeZone" },
        ]}
        data={async (query) => {
          const res = (await cronJobsService({
            input: { page: query.page + 1, query: query.search },
          })) as CronJobResponse;

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "edit",
            tooltip: "Editar CronJob",
            onClick: (_, rowData: CronJob) => onEditCronJobHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Agregar CronJob",
            isFreeAction: true,
            onClick: onNewCronJobHanlder,
          },
        ]}
      />
    </div>
  );
};

export default CronJobList;
