import * as React from 'react';
import { useSelector } from 'react-redux';
import { includesRoles } from 'libs';
import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { AuthSelector } from 'selectors/AuthSelector';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import { WarehouseType } from 'types/WarehouseType';
import { createWarehouseService, updateWarehouseService } from 'services/WarehouseService';
import { ROLES } from 'constant';


export interface WarehouseManagementModalProps {
  warehouse?: WarehouseType
}

const WarehouseManagementModal: React.FC<WarehouseManagementModalProps & ModalProps> = ({ onDismiss, onSubmit, warehouse }) => {
  const auth = useSelector(AuthSelector);
  const submitButtonRef = React.useRef<HTMLInputElement>();
  const [state, setState] = React.useState<WarehouseType>({
    uuid: warehouse?.uuid || '',
    name: warehouse?.name || '',
    current: warehouse?.current || false
  });

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    setState({
      ...state,
      [name]: value
    });
  }

  const onSubmitHandler = async () => {
    let res;
    if (!warehouse) {
      res = await createWarehouseService({ name: state.name });
    } else {
      res = await updateWarehouseService(state);
    }

    onSubmit(res);
  }

  return (
    <Modal onDismiss={onDismiss} >
      <ModalHeader>
        {warehouse ? "Editar Almacen" : 'Nuevo Almacen'}
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            <form className="space-y-6" onSubmit={async e => {
              e.preventDefault();
              await onSubmitHandler();
            }}>
              <div>
                <label htmlFor="name" className="sr-only">Almacen</label>
                <input
                  required
                  id="name"
                  name="name"
                  type="text"
                  value={state.name}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                  placeholder="Nombre"
                  onChange={onInputChange}
                />
              </div>
              <div className="mb-28">
                {includesRoles({ userRoles: auth.roles, roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN] }) && (
                  <label className="flex items-center space-x-3 mt-2">
                    <input
                      type="checkbox"
                      name="active"
                      defaultChecked={state.current}
                      className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                      onClick={() => setState({ ...state, current: !state.current })}
                    />
                    <span className="text-gray-500 font-medium">Almacen en uso</span>
                  </label>
                )}
              </div>
              <div>
                <button ref={submitButtonRef} type="submit" className="hidden" />
              </div>
            </form>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => submitButtonRef.current.click()}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onDismiss}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default WarehouseManagementModal;
