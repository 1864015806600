import { gql } from "@apollo/client";
import { ITEM_TYPE } from "constant";
import { deepCopy } from "libs";
import { authClient } from "libs/AuthClient";
import {
  CreateItem,
  ItemResponseType,
  ItemType,
  UpdateItem,
} from "types/ItemType";
import { PaginationParams } from "types/PaginationType";

const createItemService = (item: CreateItem) => {
  return authClient()
    .mutate({
      context: {
        hasUpload: true,
      },
      mutation: gql`
        mutation createItem($input: CreateItemInput!) {
          createItem(input: $input)
        }
      `,
      variables: {
        input: {
          ...item,
        },
      },
    })
    .then(() => {
      return {
        data: "Item added!",
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const updateItemService = (item: UpdateItem) => {
  return authClient()
    .mutate({
      context: {
        hasUpload: true,
      },
      mutation: gql`
        mutation updateItem($input: UpdateItemInput!) {
          updateItem(input: $input)
        }
      `,
      variables: {
        input: {
          ...item,
        },
      },
    })
    .then(() => {
      return {
        data: "Item updated!",
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const removeItemService = ({ uuid }: Pick<ItemType, "uuid">) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation removeItem($uuid: String!) {
          removeItem(uuid: $uuid)
        }
      `,
      variables: {
        uuid,
      },
    })
    .then(() => {
      return {
        data: "Item Removed!",
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const getItemsService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query getItems($input: BasePaginationInput!) {
          items(input: $input) {
            limit
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            docs {
              ... on Item {
                uuid
                name
                sku
                details
                type
                imageUrl
                available
              }
            }
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const itemsData = data.items as ItemResponseType;

      return {
        ...itemsData,
      };
    });
};

const getItemTypesService = () => {
  return authClient()
    .query({
      query: gql`
        query getItemTypes {
          itemTypes
        }
      `,
    })
    .then(({ data }): Object => {
      const itemsTypeData = data.itemTypes as ITEM_TYPE[];

      return itemsTypeData;
    });
};

const getItemsQueryService = ({ query }: { query: string }) => {
  return authClient()
    .query({
      query: gql`
        query getItemQuery($query: String!) {
          itemsQuery(query: $query) {
            uuid
            name
          }
        }
      `,
      variables: {
        query,
      },
    })
    .then(({ data }): Object => {
      const itemsData = data.itemsQuery as ItemType[];

      return itemsData;
    });
};

export {
  createItemService,
  updateItemService,
  getItemsService,
  getItemTypesService,
  getItemsQueryService,
  removeItemService,
};
