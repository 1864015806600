import { DEVICE_TYPE } from "constant";
import { ServiceType } from "./ServiceType";
import { UserType } from "./UserType";

export enum DEVICE_PAYMENT_METHOD {
  CARD = 'card',
  TOKEN = 'token',
}
export interface DeviceType {
  uuid?: string,
  name: string,
  code: string,
  services?: ServiceType[],
  users?: UserType[],
  active?: boolean,
  type?: DEVICE_TYPE
  settings: DeviceSettings;
};

export interface DeviceResponseType {
  limit: number,
  hasPrevPage: boolean,
  hasNextPage: boolean,
  page: number,
  totalPages: number,
  totalDocs: number,
  docs: DeviceType[]
};

export interface AddUserDeviceType {
  deviceUUID: string,
  userUUID: string
};

export type RemoveUserFromDeviceType = AddUserDeviceType;

export interface AddServiceDeviceType {
  deviceUUID: string,
  serviceUUID: string
};

export type RemoveServiceFromDeviceType = AddServiceDeviceType;

export interface DeviceSettings {
  paymentMethod: DEVICE_PAYMENT_METHOD[];
}