import React from "react";
import { useSelector } from "react-redux";
import { AuthSelector } from "selectors/AuthSelector";

interface NavBarProps {
  onExpand: () => void;
}

export const NavBar: React.FC<NavBarProps> = ({ onExpand }) => {
  const auth = useSelector(AuthSelector);

  return (
    <nav className="w-full flex items-center h-16 px-4 border-b border-gray-200">
      <div className="cursor-pointer" onClick={onExpand}>
        <svg
          className="block h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </div>
      <span className="rounded-full bg-pagocheck-red text-white h-8 w-8 flex items-center justify-center ml-auto uppercase">
        {auth?.user?.username?.substring(0, 2)}
      </span>
    </nav>
  );
};

export default NavBar;
