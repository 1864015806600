import * as React from "react";
import { DateTime } from "luxon";

import { useModal } from "components/UI/Modal/ModalProvider";
import Table from "components/UI/Table";
import { getWarehousesService } from "services/WarehouseService";
import { WarehouseResponseType, WarehouseType } from "types/WarehouseType";
import WarehouseManagementModal from "./WarehouseManagementModal";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { RouteConstants } from "constant";

interface WarehouseListProps {}

export const WarehouseList: React.FC<WarehouseListProps> = () => {
  const history = useHistory();
  const [limit, setLimit] = React.useState<number>(25);
  const warehouseManagementModal = useModal(WarehouseManagementModal);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewWarehouseHandler = async () => {
    const res = await warehouseManagementModal();

    if (res?.data) {
      toast.success(res?.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onEditWarehouseHandler = async (warehouse: WarehouseType) => {
    const res = await warehouseManagementModal({ warehouse });

    if (res?.data) {
      toast.success(res?.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onWarehouseItemsHandler = async (warehouse: WarehouseType) => {
    history.push(
      RouteConstants.WAREHOUSES_ITEMS.path.replace(
        ":warehouseUUID",
        warehouse.uuid
      )
    );
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de almacenes"
        limit={limit}
        columns={[
          { title: "Nombre", field: "name" },
          {
            title: "Almacen activo",
            field: "current",
            render: (rowData: WarehouseType) => (
              <span>{rowData.current ? "Si" : "No"}</span>
            ),
          },
        ]}
        data={async (query) => {
          const res = (await getWarehousesService({
            input: { page: query.page + 1, query: query.search },
          })) as WarehouseResponseType;

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          {
            icon: "edit",
            tooltip: "Editar almacen",
            onClick: (_, rowData: WarehouseType) =>
              onEditWarehouseHandler(rowData),
          },
          {
            icon: "inventory",
            tooltip: "Productos de almacen",
            onClick: (_, rowData: WarehouseType) =>
              onWarehouseItemsHandler(rowData),
          },
          {
            icon: "add",
            tooltip: "Agregar Almacen",
            isFreeAction: true,
            onClick: onNewWarehouseHandler,
          },
        ]}
      />
    </div>
  );
};

export default WarehouseList;
