import * as React from "react";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import Table from "components/UI/Table";

import { RouteConstants } from "constant";
import { getDevicesService, removeDeviceService } from "services/DeviceService";
import { useModal } from "components/UI/Modal/ModalProvider";
import { DeviceResponseType, DeviceType } from "types/DeviceType";
import DeviceManagementModal from "./DeviceManagementModal";
import { toast } from "react-toastify";
import MessageModal from "components/UI/Message/MessageModal";

interface DeviceListProps {}

export const DeviceList: React.FC<DeviceListProps> = () => {
  const history = useHistory();
  const messageModal = useModal(MessageModal);
  const [limit, setLimit] = React.useState<number>(25);
  const deviceManagementModal = useModal(DeviceManagementModal);
  const [uiTimestamp, SetUiTimestamp] = React.useState<string>(
    DateTime.now().toISO()
  );

  const onNewDeviceHandler = async () => {
    const res = await deviceManagementModal();

    if (res?.data) {
      toast.success(res?.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };
  
  const onRemoveItemHandler = async (device: DeviceType) => {
    const res = await messageModal({
      message: `Remover dispositivo ${device.name}?`,
      confirm: true,
    });
    if (res?.data) {
      const res2: any = await removeDeviceService(device.uuid);

      if (res2?.data) {
        toast.success(res2?.data);
        SetUiTimestamp(DateTime.now().toISO());
      } else if (res2?.error) {
        toast.error(res2.error);
      }
    }
  };

  const onEditDeviceHandler = async (device: DeviceType) => {
    const res = await deviceManagementModal({ device });

    if (res?.data) {
      toast.success(res?.data);
      SetUiTimestamp(DateTime.now().toISO());
    }
  };

  const onDeviceServiceHandler = async (device: DeviceType) => {
    history.push(
      RouteConstants.DEVICES_SERVICES.path.replace(":deviceUUID", device.uuid)
    );
  };

  const onDeviceUserHandler = async (device: DeviceType) => {
    history.push(
      RouteConstants.DEVICES_USERS.path.replace(":deviceUUID", device.uuid)
    );
  };

  return (
    <div key={uiTimestamp} className="h-full w-full">
      <Table
        title="Lista de dispositivos"
        limit={limit}
        columns={[
          { title: "Nombre", field: "name" },
          { title: "Codigo", field: "code" },
          { title: "Tipo", field: "type" },
          {
            title: "Activo",
            field: "active",
            render: (rowData: DeviceType) => (
              <span>{rowData.active ? "Si" : "No"}</span>
            ),
          },
        ]}
        data={async (query) => {
          const res = (await getDevicesService({
            input: { page: query.page + 1, query: query.search },
          })) as DeviceResponseType;

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          {
            icon: "edit",
            tooltip: "Editar dispositivo",
            onClick: (_, rowData: DeviceType) => onEditDeviceHandler(rowData),
          },
          {
            icon: "inventory",
            tooltip: "Administrar servicios",
            onClick: (_, rowData: DeviceType) =>
              onDeviceServiceHandler(rowData),
          },
          {
            icon: "group_add",
            tooltip: "Administrar usuarios",
            onClick: (_, rowData: DeviceType) => onDeviceUserHandler(rowData),
          },
          {
            icon: "delete",
            tooltip: "Eliminar dispositivo",
            onClick: (_, rowData: DeviceType) => onRemoveItemHandler(rowData),
          },
          {
            icon: "add",
            tooltip: "Agregar almacen",
            isFreeAction: true,
            onClick: onNewDeviceHandler,
          },
        ]}
      />
    </div>
  );
};

export default DeviceList;
