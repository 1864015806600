import { gql } from "@apollo/client";
import { deepCopy } from "libs";
import { authClient } from "libs/AuthClient";
import { PaginationParams } from "types/PaginationType";
import {
  CreateItemToWarehouseServiceType,
  GetWarehouseItemsServiceType,
  RemoveItemFromWarehouseServiceType,
  UpdateItemFromWarehouseServiceType,
  WarehouseItemsResponseType,
  WarehouseResponseType,
  WarehouseType,
} from "types/WarehouseType";

const createWarehouseService = (
  createWarehouseData: Pick<WarehouseType, "name" | "current">
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation createWarehouse($input: CreateWarehouseInput!) {
          createWarehouse(input: $input)
        }
      `,
      variables: {
        input: {
          ...createWarehouseData,
        },
      },
    })
    .then(({ data }): Object => {
      const warehouseData = data.createWarehouse as WarehouseType;

      return {
        data: warehouseData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const updateWarehouseService = (
  updateWarehouseData: Pick<WarehouseType, "uuid" | "name" | "current">
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation updateWarehouse($input: UpdateWarehouseInput!) {
          updateWarehouse(input: $input)
        }
      `,
      variables: {
        input: {
          ...updateWarehouseData,
        },
      },
    })
    .then(({ data }): Object => {
      const warehouseData = data.updateWarehouse;

      return {
        data: warehouseData,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const getWarehousesService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query getWarehouses($input: BasePaginationInput!) {
          warehouses(input: $input) {
            limit
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            docs {
              ... on Warehouse {
                uuid
                name
                current
              }
            }
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const warehouseData = data.warehouses as WarehouseResponseType;

      return {
        ...warehouseData,
      };
    });
};

const createItemToWarehouseService = (
  createItemDetails: CreateItemToWarehouseServiceType
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation createItemInWarehouse($input: CreateItemInWarehouseInput!) {
          createItemInWarehouse(input: $input)
        }
      `,
      variables: {
        input: {
          ...createItemDetails,
        },
      },
    })
    .then(({ data }): Object => {
      const itemWarehouseItem = data.createItemInWarehouse;

      return {
        data: itemWarehouseItem,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const updateItemFromWarehouseService = (
  updateItemDetails: UpdateItemFromWarehouseServiceType
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation updateItemFromWarehouse(
          $input: UpdateItemFromWarehouseInput!
        ) {
          updateItemFromWarehouse(input: $input)
        }
      `,
      variables: {
        input: {
          ...updateItemDetails,
        },
      },
    })
    .then(({ data }): Object => {
      const itemWarehouseItem = data.updateItemFromWarehouse;

      return {
        data: itemWarehouseItem,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const removeItemFromWarehouseService = (
  removeItemDetails: RemoveItemFromWarehouseServiceType
) => {
  return authClient()
    .mutate({
      mutation: gql`
        mutation removeItemFromWarehouse(
          $input: RemoveItemFromWarehouseInput!
        ) {
          removeItemFromWarehouse(input: $input)
        }
      `,
      variables: {
        input: {
          ...removeItemDetails,
        },
      },
    })
    .then(({ data }): Object => {
      const itemWarehouseItem = data.removeItemFromWarehouse;

      return {
        data: itemWarehouseItem,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

const getWarehouseItemsService = (input: PaginationParams) => {
  return authClient()
    .query({
      query: gql`
        query getWarehouseItems($input: BasePaginationInput!) {
          warehouseItems(input: $input) {
            limit
            hasPrevPage
            hasNextPage
            page
            totalPages
            totalDocs
            docs {
              ... on WarehouseItem {
                uuid
                price
                quantity
                item {
                  uuid
                  name
                  sku
                }
              }
            }
          }
        }
      `,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const warehouseItems = data.warehouseItems as WarehouseItemsResponseType;

      return {
        ...warehouseItems,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

export {
  createWarehouseService,
  updateWarehouseService,
  getWarehousesService,
  createItemToWarehouseService,
  getWarehouseItemsService,
  updateItemFromWarehouseService,
  removeItemFromWarehouseService,
};
