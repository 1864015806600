import * as React from 'react';
import { toast } from 'react-toastify';

import { Report } from 'types/Report';
import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';

import { updateReportService } from 'services/ReportService';
import { resolveFileUploads } from 'libs';


interface ReportUpdateModalProps {
  report: Report
}

const ReportUpdateModal: React.FC<ReportUpdateModalProps & ModalProps> = ({ onDismiss, onSubmit, report }) => {
  const [paid, setPaid] = React.useState<boolean>(report.paid);
  const [file, setFile] = React.useState<File>(null);


  const onFileChange = (e: React.SyntheticEvent) => {
    const element = e.currentTarget as HTMLInputElement;
    const file = element.files[0];
    if (file) {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 6) {
        toast.error('La imagen excede el tamaño');
      } else {
        setFile(file);
      }
    }
  }

  const onSubmitHandler = async () => {
    const result = await updateReportService({
      uuid: report.uuid,
      file,
      paid
    });

    onSubmit(result);
  }

  return (
    <Modal>
      <ModalHeader>
        Editar Reporte
      </ModalHeader>
      <ModalBody>
        <div className="w-full">
          <div className="mt-4">
            <label className="inline-block mb-2 text-gray-500">Subir Recibo</label>
            <div className="flex items-center justify-center w-full">
              <label className="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                <div className="flex flex-col items-center justify-center pt-7">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                      d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                      clipRule="evenodd" />
                  </svg>
                  <p
                    className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600"
                  >
                    Subir Recibo
                  </p>
                </div>
                <input type="file" className="opacity-0" onChange={onFileChange} />
              </label>
            </div>
          </div>
          {report.documentUrlExtra && (
            <div className="flex flex-col w-full mt-2">
              <label htmlFor="document">Recibo Actual</label>
              <a id="document" className="p-2 rounded border border-gray-300 w-full text-center bg-gray-600 hover:bg-gray-900 text-white"
                href={resolveFileUploads(report.documentUrlExtra)}
              >
                Descargar
              </a>
            </div>
          )}
          <div className="mt-4">
            <label className="flex items-center space-x-3">
              <input
                type="checkbox"
                name="active"
                defaultChecked={paid}
                className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                onClick={() => setPaid(!paid)}
              />
              <span className="text-gray-500 font-medium">Pagado</span>
            </label>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onSubmitHandler}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onDismiss}
        >
          Atrás
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default ReportUpdateModal;
