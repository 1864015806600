import * as React from "react";
import { DateTime } from "luxon";
import NumberFormat from "react-number-format";
import { gql, useLazyQuery } from "@apollo/client";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import ReactDatePicker from "react-datepicker";

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-gray-400 p-4 text-gray-200">
        <p className="label">{`Mes de ${label}`}</p>
        <p className="label">
          <NumberFormat
            value={payload[0].value}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </p>
      </div>
    );
  }

  return null;
};

const QUERY = gql`
  query incomeByYear($date: Date!) {
    incomeByYear(date: $date) {
      month
      income
    }
  }
`;

interface AreaChartProps {
  title?: string;
}

const AreaGraph: React.FC<AreaChartProps> = ({ title }) => {
  const [date, setDate] = React.useState<Date>(new Date());
  const [getData, { data, loading }] = useLazyQuery(QUERY);

  const fetchData = React.useCallback(() => {
    getData({ variables: { date } });
  }, [date]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="w-full h-full flex flex-col space-y-4">
      <div className="flex flex-row bg-gray-300 p-2 mx-4">
        <h1 className="w-2/3 text-2xl">{title}</h1>
        <div className="w-1/3 flex flex-row items-center space-x-2">
          <span>Año: </span>
          <ReactDatePicker
            className="w-full text-base px-4 text-center rounded focus:outline-none focus:ring"
            selected={date}
            onChange={(d: Date) => setDate(d)}
            showYearPicker
            dateFormat="yyyy"
            yearItemNumber={9}
          />
        </div>
      </div>
      {loading ? (
        "loading..."
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={data?.incomeByYear}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="income"
              stroke="#1f2937f0"
              fill="#1f2937f0"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default AreaGraph;
