import * as React from "react";
import AsyncSelect from "react-select/async";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import { ModalProps } from "components/UI/Modal/ModalProvider";
import { Modal } from "components/UI";
import ModalBody from "components/UI/Modal/ModalBody";
import ModalFooter from "components/UI/Modal/ModalFooter";
import ModalHeader from "components/UI/Modal/ModalHeader";
import { Location, PlaceSettings, PlaceType } from "types/PlaceType";
import { CityType } from "types/CityType";
import { getCitiesQueryService } from "services/CityService";
import { createPlaceService, updatePlaceService } from "services/PlaceService";
import { placeGroupQueryService } from "services/PlaceGroupService";
import { PlaceGroup } from "types/PlaceGroup";
import { removeKeysFromObject } from "libs";
import { ServiceType } from "types/ServiceType";
import { getServiceQueryService } from "services/ServiceService";

export interface UserManagementModalProps {
  place?: PlaceType;
}

const PlaceManagementModal: React.FC<UserManagementModalProps & ModalProps> = ({
  onDismiss,
  onSubmit,
  place,
}) => {
  const submitButtonRef = React.useRef<HTMLInputElement>();
  const [cityOption, setCityOption] = React.useState<{
    label: string;
    value: CityType;
  }>(place?.city ? { label: place.city.name, value: place.city } : null);
  const [placeGroupOption, setPlaceGroupOption] = React.useState<{
    label: string;
    value: PlaceGroup;
  }>(
    place?.placeGroup
      ? { label: place.placeGroup.name, value: place.placeGroup }
      : null
  );
  const [inheritServices, setInheritServices] = React.useState<boolean>(false);
  const [state, setState] = React.useState<PlaceType>({
    uuid: place?.uuid || "",
    name: place?.name || "",
    code: place?.code || "",
    address: place?.address || "",
    placeNumber: place?.placeNumber || 0,
    active: place?.active || false,
    settings: place?.settings || null,
  });
  const [location, setLocation] = React.useState<string>(null);
  const [placeServicesOptions, setPlaceServicesOptions] = React.useState<
    { label: string; value: ServiceType }[]
  >([]);

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;
    let s = {
      ...state.settings,
    };

    if (["comission", "comissionPercent"].includes(name)) {
      s = {
        ...s,
        [name]: value.match(/^[0-9]*\.?[0-9]*$/) ? value : "",
      };
    }

    setState({
      ...state,
      settings: s,
      [name]: value,
    });
  };

  const onSubmitHandler = async () => {
    let res;

    const settings: PlaceSettings = state.settings
      ? removeKeysFromObject(state.settings, ["__typename"])
      : null;

    if (settings?.overrideComission) {
      settings.comission = parseFloat(`${settings.comission}`);
      settings.comissionPercent = parseFloat(`${settings.comissionPercent}`);
    }

    if (place) {
      res = await updatePlaceService({
        uuid: state.uuid,
        name: state.name,
        code: state.code,
        address: state.address,
        active: state.active,
        placeNumber: parseInt(`${state.placeNumber}`),
        cityUUID: cityOption.value.uuid,
        placeGroupUUID: placeGroupOption.value.uuid,
        location: JSON.parse(location) ?? state.location,
        servicesUUID: placeServicesOptions.map(op => op.value.uuid),
        settings,
      });
    } else {
      res = await createPlaceService({
        name: state.name,
        code: state.code,
        address: state.address,
        active: state.active,
        placeNumber: parseInt(`${state.placeNumber}`),
        cityUUID: cityOption.value.uuid,
        placeGroupUUID: placeGroupOption.value.uuid,
        location: JSON.parse(location),
        servicesUUID: placeServicesOptions.map(op => op.value.uuid),
        inheritServices,
        settings,
      });
    }

    onSubmit(res);
  };

  const loadCities = (inputValue: string) => {
    return getCitiesQueryService({ query: inputValue }).then(
      (cities: CityType[]) => {
        return cities.map((city) => ({
          label: city.name,
          value: city,
        }));
      }
    );
  };

  const loadPlaceGroups = (inputValue: string) => {
    return placeGroupQueryService({ query: inputValue }).then(
      (placeGroups: PlaceGroup[]) => {
        return placeGroups.map((placeGroup) => ({
          label: placeGroup.name,
          value: placeGroup,
        }));
      }
    );
  };

  const loadIServices = (inputValue: string) => {
    return getServiceQueryService({ query: inputValue }).then(
      (services: ServiceType[]) => {
        return services.map((service) => ({
          label: service.name,
          value: service,
        }));
      }
    );
  };


  React.useEffect(() => {
    if(!placeServicesOptions.length && place?.services){
      const d = place?.services.map(s => ({ label: s.name, value: s }));

      setPlaceServicesOptions(d);
    }
  }, [placeServicesOptions, place]);

  return (
    <Modal onDismiss={onDismiss} variant="lg">
      <ModalHeader>{place ? "Editar Sucursal" : "Nueva Sucursal"}</ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            <form
              className="space-y-6"
              onSubmit={async (e) => {
                e.preventDefault();
                await onSubmitHandler();
              }}
            >
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="name" className="sr-only">
                    Nombre de sucursal
                  </label>
                  <input
                    required
                    id="name"
                    name="name"
                    type="text"
                    value={state.name}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Nombre de sucursal"
                    onChange={onInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="code" className="sr-only">
                    Código de sucursal
                  </label>
                  <input
                    required
                    id="code"
                    name="code"
                    type="text"
                    value={state.code}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Código de sucursal (único)"
                    onChange={onInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="address" className="sr-only">
                    Dirección de sucursal
                  </label>
                  <input
                    required
                    id="address"
                    name="address"
                    type="text"
                    value={state.address}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Dirección de sucursal"
                    onChange={onInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="placeNumber" className="sr-only">
                    Número de sucursal
                  </label>
                  <input
                    required
                    id="placeNumber"
                    name="placeNumber"
                    type="number"
                    value={state.placeNumber}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder="Número de sucursal"
                    onChange={onInputChange}
                  />
                </div>
              </div>

              <div className="relative">
                <label htmlFor="placeGroup" className="sr-only">
                  Grupo
                </label>
                <AsyncSelect
                  id="placeGroup"
                  name="placeGroup"
                  placeholder="Grupo"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={placeGroupOption}
                  value={placeGroupOption}
                  loadOptions={loadPlaceGroups}
                  onChange={(placeGroup) =>
                    setPlaceGroupOption(placeGroup as any)
                  }
                />
                {!placeGroupOption && (
                  <input
                    required
                    name="placeGroup"
                    className="outline-none focus:outline-none absolute"
                    style={{ height: 1, opacity: 0 }}
                  />
                )}
              </div>

              <div className="relative">
                <label htmlFor="ciudad" className="sr-only">
                  Ciudad
                </label>
                <AsyncSelect
                  id="ciudad"
                  name="ciudad"
                  placeholder="Ciudad"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={cityOption}
                  value={cityOption}
                  loadOptions={loadCities}
                  onChange={(city) => setCityOption(city as any)}
                />
                {!cityOption && (
                  <input
                    required
                    name="item"
                    className="outline-none focus:outline-none absolute"
                    style={{ height: 1, opacity: 0 }}
                  />
                )}
              </div>

              <div className="mb-28">
                <label className="flex items-center space-x-3 mt-2">
                  <input
                    type="checkbox"
                    name="active"
                    defaultChecked={state.settings?.overrideComission}
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                    onClick={() =>
                      setState({
                        ...state,
                        settings: {
                          ...state.settings,
                          overrideComission: !state.settings?.overrideComission,
                        },
                      })
                    }
                  />
                  <span className="text-gray-500 font-medium">
                    Forzar comisión por sucursal
                  </span>
                </label>
              </div>

              {state.settings?.overrideComission && (
                <>
                  <div>
                    <label htmlFor="comission" className="text-gray-500">
                      Comisión a aplicar
                    </label>
                    <input
                      id="comission"
                      name="comission"
                      type="text"
                      value={state.settings?.comission ?? ""}
                      className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      onChange={onInputChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="comissionPercent" className="text-gray-500">
                      Porcentaje de comisión a aplicar
                    </label>
                    <input
                      id="comissionPercent"
                      name="comissionPercent"
                      type="text"
                      value={state.settings?.comissionPercent ?? ""}
                      className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      onChange={onInputChange}
                    />
                  </div>
                </>
              )}

              <div className="relative">
                <label htmlFor="ciudad" className="text-gray-600">
                  Servicios
                </label>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  id="item"
                  name="servicio"
                  placeholder="Servicios asignados"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={placeServicesOptions}
                  value={placeServicesOptions}
                  loadOptions={loadIServices}
                  onChange={(item) => setPlaceServicesOptions(item as any)}
                />
                {placeServicesOptions?.length === 0 && (
                  <input
                    required
                    name="item"
                    className="outline-none focus:outline-none absolute"
                    style={{ height: 1, opacity: 0 }}
                  />
                )}
              </div>

              <div className="relative space-y-2">
                <label htmlFor="ciudad" className="text-gray-600">
                  Configuración de Geocerca
                </label>
                <JSONInput
                  id="a_unique_id"
                  placeholder={place?.location}
                  style={{
                    outerBox: {
                      width: "100%",
                    },
                    container: {
                      width: "100%",
                    },
                  }}
                  onChange={({ json, ...rest }: any) => {
                    setLocation(json);
                  }}
                  locale={locale}
                  height="400px"
                />
                <a
                  className="text-gray-800 hover:text-blue-600"
                  href="https://geojson.io/"
                  target="_blank"
                >
                  Herramienta
                </a>
              </div>

              <div className="mb-28">
                <label className="flex items-center space-x-3 mt-2">
                  <input
                    type="checkbox"
                    name="active"
                    defaultChecked={state.active}
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                    onClick={() =>
                      setState({ ...state, active: !state.active })
                    }
                  />
                  <span className="text-gray-500 font-medium">Activa</span>
                </label>
              </div>

              {!place && (
                <div className="mb-28">
                  <label className="flex items-center space-x-3 mt-2">
                    <input
                      type="checkbox"
                      name="inheritServices"
                      defaultChecked={inheritServices}
                      className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                      onClick={() => setInheritServices(!inheritServices)}
                    />
                    <span className="text-gray-500 font-medium">
                      Heredar Servicios de la compañia
                    </span>
                  </label>
                </div>
              )}

              <div>
                <button
                  ref={submitButtonRef}
                  type="submit"
                  className="hidden"
                />
              </div>
            </form>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 hover:bg-red-700 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => submitButtonRef.current.click()}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onDismiss}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default PlaceManagementModal;
